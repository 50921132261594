/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountRoleModel } from '../models/account-role-model';
import { accountRolesDeleteRoleById } from '../fn/account/account-roles-delete-role-by-id';
import { AccountRolesDeleteRoleById$Params } from '../fn/account/account-roles-delete-role-by-id';
import { accountRolesGetRoleById } from '../fn/account/account-roles-get-role-by-id';
import { AccountRolesGetRoleById$Params } from '../fn/account/account-roles-get-role-by-id';
import { accountRolesGetRoles } from '../fn/account/account-roles-get-roles';
import { AccountRolesGetRoles$Params } from '../fn/account/account-roles-get-roles';
import { AccountRolesGridResponse } from '../models/account-roles-grid-response';
import { accountRolesSaveRole } from '../fn/account/account-roles-save-role';
import { AccountRolesSaveRole$Params } from '../fn/account/account-roles-save-role';

@Injectable({ providedIn: 'root' })
export class RolesManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountRolesGetRoles()` */
  static readonly AccountRolesGetRolesPath = '/api/account/roles';

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles$Response(params: AccountRolesGetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountRolesGridResponse>> {
    return accountRolesGetRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles(params: AccountRolesGetRoles$Params, context?: HttpContext): Observable<AccountRolesGridResponse> {
    return this.accountRolesGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRolesGridResponse>): AccountRolesGridResponse => r.body)
    );
  }

  /** Path part for operation `accountRolesGetRoleById()` */
  static readonly AccountRolesGetRoleByIdPath = '/api/account/role/{id}';

  /**
   * Get account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById$Response(params: AccountRolesGetRoleById$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountRoleModel>> {
    return accountRolesGetRoleById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById(params: AccountRolesGetRoleById$Params, context?: HttpContext): Observable<AccountRoleModel> {
    return this.accountRolesGetRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRoleModel>): AccountRoleModel => r.body)
    );
  }

  /** Path part for operation `accountRolesDeleteRoleById()` */
  static readonly AccountRolesDeleteRoleByIdPath = '/api/account/role/{id}';

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesDeleteRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById$Response(params: AccountRolesDeleteRoleById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountRolesDeleteRoleById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesDeleteRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById(params: AccountRolesDeleteRoleById$Params, context?: HttpContext): Observable<void> {
    return this.accountRolesDeleteRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountRolesSaveRole()` */
  static readonly AccountRolesSaveRolePath = '/api/account/role';

  /**
   * Save role details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesSaveRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole$Response(params: AccountRolesSaveRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountRolesSaveRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Save role details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesSaveRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole(params: AccountRolesSaveRole$Params, context?: HttpContext): Observable<void> {
    return this.accountRolesSaveRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
