import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Router, NavigationEnd } from '@angular/router';
import { Observable, of, Subject, timer } from 'rxjs';
import { filter, take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DelayedPreloadingStrategy implements PreloadingStrategy {
  private preloadTriggered = new Subject<void>();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1), // Take only the first NavigationEnd event (initial navigation)
        switchMap(() => timer(2000)), // Add a delay of 2000 ms (2 seconds)
      )
      .subscribe(() => {
        // After the delay, emit to start preloading
        this.preloadTriggered.next();
        this.preloadTriggered.complete();
      });
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.preloadTriggered.pipe(
      switchMap(() => load())
    );
  }
}
