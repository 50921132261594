/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { recordingsV2DeleteRecording } from '../fn/recordings/recordings-v-2-delete-recording';
import { RecordingsV2DeleteRecording$Params } from '../fn/recordings/recordings-v-2-delete-recording';
import { recordingsV2DownloadRecording } from '../fn/recordings/recordings-v-2-download-recording';
import { RecordingsV2DownloadRecording$Params } from '../fn/recordings/recordings-v-2-download-recording';
import { recordingsV2StreamRecording } from '../fn/recordings/recordings-v-2-stream-recording';
import { RecordingsV2StreamRecording$Params } from '../fn/recordings/recordings-v-2-stream-recording';

@Injectable({ providedIn: 'root' })
export class RecordingsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `recordingsV2StreamRecording()` */
  static readonly RecordingsV2StreamRecordingPath = '/api/recordings-v2/recording/stream/{recordingId}/{callId}';

  /**
   * Stream Recording.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recordingsV2StreamRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2StreamRecording$Response(params: RecordingsV2StreamRecording$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return recordingsV2StreamRecording(this.http, this.rootUrl, params, context);
  }

  /**
   * Stream Recording.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recordingsV2StreamRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2StreamRecording(params: RecordingsV2StreamRecording$Params, context?: HttpContext): Observable<void> {
    return this.recordingsV2StreamRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `recordingsV2DownloadRecording()` */
  static readonly RecordingsV2DownloadRecordingPath = '/api/recordings-v2/recording/download/{recordingId}/{callId}';

  /**
   * Download Recording.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recordingsV2DownloadRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DownloadRecording$Response(params: RecordingsV2DownloadRecording$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return recordingsV2DownloadRecording(this.http, this.rootUrl, params, context);
  }

  /**
   * Download Recording.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recordingsV2DownloadRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DownloadRecording(params: RecordingsV2DownloadRecording$Params, context?: HttpContext): Observable<void> {
    return this.recordingsV2DownloadRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `recordingsV2DeleteRecording()` */
  static readonly RecordingsV2DeleteRecordingPath = '/api/recordings-v2/recording/delete/{recordingId}/{callId}';

  /**
   * Delete Recording.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recordingsV2DeleteRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DeleteRecording$Response(params: RecordingsV2DeleteRecording$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return recordingsV2DeleteRecording(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Recording.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recordingsV2DeleteRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DeleteRecording(params: RecordingsV2DeleteRecording$Params, context?: HttpContext): Observable<void> {
    return this.recordingsV2DeleteRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
