import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '../../notification/notification.service';
import { AuthService } from '../security/auth.service';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private cookieService: CookieService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('API Interceptor: ' + req.url);
    const canaryCookieBefore = this.cookieService.get('x-beta-customer');

    // Add ngsw-bypass=true to the request URL
    //const modifiedReq = this.addNgSwBypass(req);
    const modifiedReq = req;

    return next.handle(modifiedReq).pipe(
      tap(() => {
        // Success handler
        this.checkCanaryCookie(canaryCookieBefore);
      }),
      catchError(error => {
        // Error handler
        this.handleErrorResponse(error);
        return throwError(() => error);
      }),
    );
  }

  /**
   * Adds ngsw-bypass=true as a query parameter to bypass Angular Service Worker cache.
   * @param req HttpRequest to be modified.
   * @returns Modified HttpRequest with ngsw-bypass=true query parameter.
   */
  private addNgSwBypass(req: HttpRequest<any>): HttpRequest<any> {
    // Clone the request and set the new query parameter
    const params = req.params.set('ngsw-bypass', 'true');
    return req.clone({ params });
  }

  /**
   * Checks if the canary cookie has changed and reloads the page if necessary.
   * @param canaryCookieBefore The value of the canary cookie before the request.
   */
  private checkCanaryCookie(canaryCookieBefore: string): void {
    const canaryCookieAfter = this.cookieService.get('x-beta-customer');

    if (canaryCookieBefore !== canaryCookieAfter) {
      console.log('Canary cookie has changed.');
      if (!location.href.includes('/auth/login')) {
        console.log('Reloading page due to canary cookie change.');
        location.reload();
      }
    }
  }

  /**
   * Handles HTTP errors globally.
   * @param error The error response to handle.
   */
  private handleErrorResponse(error: any): void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        // Unauthorized error, redirect to login
        console.log(`HTTP 401 Unauthorized: Logging out ${location.href}`);
        this.authService.logout().catch(err => {
          console.error('Logout error:', err);
        });
      } else {
        this.processServerError(error);
      }
    } else {
      console.error('An unknown error occurred:', error);
      this.notificationService.notifyDanger('An unknown error occurred.');
    }
  }

  /**
   * Processes server errors and displays notifications.
   * @param error The HttpErrorResponse containing the error details.
   */
  private processServerError(error: HttpErrorResponse): void {
    try {
      let errorBody = error.error;

      if (typeof errorBody === 'string') {
        errorBody = JSON.parse(errorBody);
      }

      if (errorBody && errorBody.status !== 404.109 && errorBody.message) {
        const errorMessage =
          typeof errorBody.message === 'object'
            ? JSON.stringify(errorBody.message)
            : errorBody.message;
        this.notificationService.notifyDanger(errorMessage);
      }
    } catch (e) {
      console.error('Error processing server error:', e);
      this.notificationService.notifyDanger('An unknown server error occurred.');
    }
  }
}
