import 'twix';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {AuthService} from '../core/security/auth.service';
import {CurrentUser} from '../api/models/current-user';
import {ReportsService as ReportsApiService} from '../api/services/reports.service';
import {Subscription} from 'rxjs';
import {LookupModel} from '../api/models/lookup-model';

@Injectable()
export class ExtensionsSelectService {

  public accountsLookupList: LookupModel[] = [];
  public extensionsLookupList: LookupModel[] = [];
  public groupsLookupList: LookupModel[] = [];
  public departmentsLookupList: LookupModel[] = [];
  public otherAccountsLookupList: LookupModel[] = [];

  public otherAccountsLookup: any[] = [];
  public accountsLookup: any[] = [];
  public extensionsLookup: any[] = [];
  public groupsLookup: any[] = [];
  public departmentsLookup: any[] = [];

  public selectedDomain: string;

  currentUserSubscription: Subscription;

  constructor(private authService: AuthService,
              private reportsApiService: ReportsApiService) {

    this.currentUserSubscription = this.authService.currentUser.subscribe((value?: CurrentUser) => {
      if (value) {
        this.selectedDomain = value.domain;
      } else {
        this.selectedDomain = '';
      }

      if (this.selectedDomain) {
        this.pbxAccountLookup();
      }
    });
  }

  async pbxAccountLookup() {

    const response = await this.reportsApiService.reportsGetPbxAccountData({domain: this.selectedDomain}).toPromise();

    this.accountsLookup = response.map((x) => {
      let name = x.fullName;
      if (x.firstName || x.displayName) {
        name = `${x.firstName || ''} ${x.displayName || ''}`;
      }

      return {
        text: `${name || ''} (${x.extension})`.trim(),
        value: x.extension,
        id: _.uniqueId('id_')
      };
    });

    this.accountsLookupList = this.accountsLookup.map(x => {
      return {
        text: x.text,
        id: x.value
      };
    });

    this.extensionsLookup = response.filter((x) => x.type === 'extensions').map((x) => {
      let name = x.fullName;
      if (x.firstName || x.displayName) {
        name = `${x.firstName || ''} ${x.displayName || ''}`;
      }

      return {
        text: `${name || ''} (${x.extension})`.trim(),
        value: x.extension,
        id: _.uniqueId('id_')
      };
    });

    this.extensionsLookupList = this.extensionsLookup.map(x => {
      return {
        text: x.text,
        id: x.value
      };
    });

    this.groupsLookup = response.filter((x) => x.type === 'acds' || x.type === 'hunts').map((x) => {
      let name = x.fullName;
      if (x.firstName || x.displayName) {
        name = `${x.firstName || ''} ${x.displayName || ''}`;
      }

      return {
        text: `${name || ''} (${x.extension})`.trim(),
        value: x.extension,
        id: _.uniqueId('id_')
      };
    });

    this.groupsLookupList = this.groupsLookup.map(x => {
      return {
        text: x.text,
        id: x.value
      };
    });

    const departments = response.filter((x) => x.type === 'extensions' && x.position).map((x) => {
      return {
        text: `${x.position || ''}`.trim(),
        value: x.extension
      };
    });

    // const departmentsGroup = _.groupBy(departments, 'text');

    const grouped = _.mapValues(_.groupBy(departments, 'text'),
      item => {
        const values = item.map(car => _.omit(car, 'text'));
        const id = _.uniqueId('id_');
        return {
          text: item[0].text,
          value: item[0].text,
          extensions: values.map((x) => x.value),
          id: id
        };
      });

    this.departmentsLookup = _.values(grouped);

    this.departmentsLookupList = this.departmentsLookup.map(x => {
      return {
        text: x.text,
        id: x.value
      };
    });

    this.otherAccountsLookup = response
      .filter((x) => x.type !== 'acds' && x.type !== 'hunts' && x.type !== 'extensions')
      .map((x) => {
        let name = x.fullName;
        if (x.firstName || x.displayName) {
          name = `${x.firstName || ''} ${x.displayName || ''}`;
        }

        return {
          text: `${name || ''} (${x.extension})`.trim(),
          value: x.extension,
          id: _.uniqueId('id_')
        };
      });

    this.otherAccountsLookupList = this.otherAccountsLookup.map(x => {
      return {
        text: x.text,
        id: x.value
      };
    });
  }

  getDepartmentsExtensions(departments: string[]) {
    const selectedDepartments = this.departmentsLookup.filter((item) => {
      return departments.indexOf(item.value) > -1;
    });

    let selectedExtensions = [];

    for (const department of selectedDepartments) {
      selectedExtensions = _.union(selectedExtensions, department.extensions);
    }

    return selectedExtensions;
  }

  getExtensionName(extension: string) {
    const lookup = this.accountsLookup.find((item) => item.value === extension);
    if (lookup) {
      return lookup.text;
    }
    return extension;
  }
}
