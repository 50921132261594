import {Component, OnInit} from '@angular/core';
import {NotificationModel, NotificationService} from './notification.service';
import {animate, group, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-notification',
  template: `
    <div class="notification-container">
      <div *ngFor="let notification of notificationService.notifications" [@flyInOut]="'in'">
        <div class="alert {{notification.type}} alert-dismissible" role="alert" title="{{notification.message}}">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onRemove(notification)">
            <span aria-hidden="true">×</span>
          </button>
          {{notification.message}}
        </div>
      </div>
    </div>
  `,
  styles: ['.notification-container {position: fixed; right: 15px; top: 15px; z-index: 111}'],
  animations: [
    trigger('flyInOut', [
      state('in', style({width: 300, transform: 'translateX(0)', opacity: 1})),
      transition('void => *', [
        style({width: 10, transform: 'translateX(50px)', opacity: 0}),
        group([
          animate('0.3s 0.1s ease', style({
            transform: 'translateX(0)',
            width: 300
          })),
          animate('0.3s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.3s ease', style({
            transform: 'translateX(50px)',
            width: 10
          })),
          animate('0.3s 0.2s ease', style({
            opacity: 0
          }))
        ])
      ])
    ])
  ]
})
export class NotificationComponent implements OnInit {

  constructor(public notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    console.log('NotificationComponent - ngOnInit');
  }

  public onRemove(notification: NotificationModel) {
    this.notificationService.remove(notification);
  }
}
