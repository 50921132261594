/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanResponse } from '../models/boolean-response';
import { pbxDigiumGetCallsHistory } from '../fn/pbx-digium/pbx-digium-get-calls-history';
import { PbxDigiumGetCallsHistory$Params } from '../fn/pbx-digium/pbx-digium-get-calls-history';
import { pbxDigiumMakeCall } from '../fn/pbx-digium/pbx-digium-make-call';
import { PbxDigiumMakeCall$Params } from '../fn/pbx-digium/pbx-digium-make-call';
import { RecentCallModel } from '../models/recent-call-model';

@Injectable({ providedIn: 'root' })
export class PbxDigiumService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxDigiumMakeCall()` */
  static readonly PbxDigiumMakeCallPath = '/api/pbx-digium/make-call';

  /**
   * Initiate remote call.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxDigiumMakeCall()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxDigiumMakeCall$Response(params: PbxDigiumMakeCall$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return pbxDigiumMakeCall(this.http, this.rootUrl, params, context);
  }

  /**
   * Initiate remote call.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxDigiumMakeCall$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxDigiumMakeCall(params: PbxDigiumMakeCall$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.pbxDigiumMakeCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `pbxDigiumGetCallsHistory()` */
  static readonly PbxDigiumGetCallsHistoryPath = '/api/pbx-digium/calls-history';

  /**
   * Get calls history.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxDigiumGetCallsHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxDigiumGetCallsHistory$Response(params?: PbxDigiumGetCallsHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecentCallModel>>> {
    return pbxDigiumGetCallsHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get calls history.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxDigiumGetCallsHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxDigiumGetCallsHistory(params?: PbxDigiumGetCallsHistory$Params, context?: HttpContext): Observable<Array<RecentCallModel>> {
    return this.pbxDigiumGetCallsHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecentCallModel>>): Array<RecentCallModel> => r.body)
    );
  }

}
