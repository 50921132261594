/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ContactsImportResponse } from '../../models/contacts-import-response';
import { FileUploadModel } from '../../models/file-upload-model';

export interface ContactSyncImportCsv$Params {
  
    /**
     * Csv Attachment
     */
    body: FileUploadModel
}

export function contactSyncImportCsv(http: HttpClient, rootUrl: string, params: ContactSyncImportCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactsImportResponse>> {
  const rb = new RequestBuilder(rootUrl, contactSyncImportCsv.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ContactsImportResponse>;
    })
  );
}

contactSyncImportCsv.PATH = '/api/contact/import-csv';
