/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadDownload } from '../fn/download/download-download';
import { DownloadDownload$Params } from '../fn/download/download-download';

@Injectable({ providedIn: 'root' })
export class DownloadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `downloadDownload()` */
  static readonly DownloadDownloadPath = '/api/download/{filename}';

  /**
   * Download specified file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDownload$Response(params: DownloadDownload$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return downloadDownload(this.http, this.rootUrl, params, context);
  }

  /**
   * Download specified file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDownload(params: DownloadDownload$Params, context?: HttpContext): Observable<{
}> {
    return this.downloadDownload$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
