import {Injectable, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import 'twix';
import {DateTime} from 'luxon';
import {DateUtils} from '../utilities/date-time-helpers';
import {BehaviorSubject} from 'rxjs';

export interface ISelectedDateRange {
  action: number;
  text: string;
  title: string;
}

@Injectable()
export class DateRangeService implements OnDestroy {
  public selectedDateRangeSubject = new BehaviorSubject<ISelectedDateRange>(null);
  public rangeStartMoment: DateTime;
  public rangeEndMoment: DateTime;
  public rangeStartDate: Date;
  public rangeEndDate: Date;
  public selectedDateRange: ISelectedDateRange = {action: 0, text: 'Today', title: 'Today'};
  public dateRanges = [
    {action: 99, text: 'Custom Range', title: 'Custom Range'},
    {action: 0, text: 'Today', title: 'Today'},
    {action: 1, text: 'Yesterday', title: 'Yesterday'},
    {action: 2, text: 'This Week', title: 'This Week'},
    {action: 3, text: 'Last Week', title: 'Last Week'},
    {action: 4, text: 'This Month', title: 'This Month'},
    {action: 5, text: 'Last Month', title: 'Last Month'},
    {action: 6, text: 'Last 30 days', title: 'Last 30 days'},
    {action: 7, text: 'Last 60 days', title: 'Last 60 days'},
    {action: 8, text: 'Last 90 days', title: 'Last 90 days'},
    {action: 9, text: 'Last 180 days', title: 'Last 180 days'},
  ];

  constructor() {

    moment.updateLocale('en', {
      week: {
        dow: 1,
        doy: 1
      },
    });

    this.onDateRangeChange(this.dateRanges[1]);
  }

  selectDateRangeByType(type: number, startDate?: string, endDate?: string) {
    console.log('date range selected by: ', type, startDate, endDate);
    const dateRange = this.dateRanges.find(x => x.action === Number(type));
    if (dateRange) {
      this.onDateRangeChange(dateRange, dateRange.action === 99);

      if (dateRange.action === 99 && startDate && endDate) {
        const startDateDT = DateTime.fromISO(startDate);
        const endDateDT = DateTime.fromISO(endDate);

        const startDateFixed = DateUtils.localDateFromDateTime(startDateDT);
        const endDateFixed = DateUtils.localDateFromDateTime(endDateDT);

        this.setCustomRange(startDateFixed, endDateFixed);
      }
    }
  }

  onDateRangeChange(selectedItem: any, forceSelect = false) {
    console.log(selectedItem);

    const periodType = selectedItem.action;

    if (periodType !== 99 || forceSelect) {
      this.selectedDateRange = selectedItem;
    }

    if (periodType === 0) { // today
      this.rangeStartMoment = DateTime.local().startOf('day');
      this.rangeEndMoment = DateTime.local().startOf('day').plus({'days': 1});
    } else if (periodType === 1) { // yesterday
      this.rangeStartMoment = DateTime.local().startOf('day').plus({'days': -1});
      this.rangeEndMoment = DateTime.local().startOf('day');
    } else if (periodType === 2) { // This Week
      this.rangeStartMoment = DateTime.local().startOf('week');
      this.rangeEndMoment = DateTime.local().endOf('week').startOf('day').plus({'days': 1});
    } else if (periodType === 3) { // Last Week
      this.rangeStartMoment = DateTime.local().plus({'weeks': -1}).startOf('week');
      this.rangeEndMoment = DateTime.local().plus({'weeks': -1}).endOf('week').startOf('day').plus({'days': 1});
    } else if (periodType === 4) { // This Month
      this.rangeStartMoment = DateTime.local().startOf('month');
      this.rangeEndMoment = DateTime.local().endOf('month').startOf('day').plus({'days': 1});
    } else if (periodType === 5) { // Last Month
      this.rangeStartMoment = DateTime.local().plus({'months': -1}).startOf('month');
      this.rangeEndMoment = DateTime.local().plus({'months': -1}).endOf('month').startOf('day').plus({'days': 1});
    } else if (periodType === 6) { // Last 30 days
      this.rangeStartMoment = DateTime.local().startOf('day').plus({'days': -30});
      this.rangeEndMoment = DateTime.local().startOf('day').plus({'days': 1});
    } else if (periodType === 7) { // Last 60 days
      this.rangeStartMoment = DateTime.local().startOf('day').plus({'days': -60});
      this.rangeEndMoment = DateTime.local().startOf('day').plus({'days': 1});
    } else if (periodType === 8) { // Last 90 days
      this.rangeStartMoment = DateTime.local().startOf('day').plus({'days': -90});
      this.rangeEndMoment = DateTime.local().startOf('day').plus({'days': 1});
    } else if (periodType === 9) { // Last 180 days
      this.rangeStartMoment = DateTime.local().startOf('day').plus({'days': -180});
      this.rangeEndMoment = DateTime.local().startOf('day').plus({'days': 1});
    }

    console.log(this.rangeStartMoment.toISO());
    console.log(this.rangeEndMoment.toISO());

    this.rangeStartDate = DateUtils.localDateFromDateTime(this.rangeStartMoment);
    this.rangeEndDate = DateUtils.localDateFromDateTime(this.rangeEndMoment);

    this.selectedDateRangeSubject.next(this.selectedDateRange);
  }

  setCustomRange(startDate: Date, endDate: Date) {

    this.rangeStartMoment = DateTime.local(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      startDate.getDate(),
      startDate.getHours(),
      startDate.getMinutes(),
      startDate.getSeconds()
    );

    this.rangeEndMoment = DateTime.local(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      endDate.getDate(),
      endDate.getHours(),
      endDate.getMinutes(),
      endDate.getSeconds()
    );

    this.rangeStartDate = DateUtils.localDateFromDateTime(this.rangeStartMoment);
    this.rangeEndDate = DateUtils.localDateFromDateTime(this.rangeEndMoment);

    console.log(this.rangeStartDate, this.rangeEndDate);

    const range = moment(startDate).twix(endDate, {allDay: true});

    if (this.selectedDateRange.action === 99) {
      this.selectedDateRange.title = range.format();
    }
  }

  ngOnDestroy() {
    console.log('Destroyed DateRangeService');
  }
}
