/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { TagModel } from '../models/tag-model';
import { tagsDeleteTagById } from '../fn/tags/tags-delete-tag-by-id';
import { TagsDeleteTagById$Params } from '../fn/tags/tags-delete-tag-by-id';
import { tagsGetTags } from '../fn/tags/tags-get-tags';
import { TagsGetTags$Params } from '../fn/tags/tags-get-tags';
import { tagsGetTagsForGrid } from '../fn/tags/tags-get-tags-for-grid';
import { TagsGetTagsForGrid$Params } from '../fn/tags/tags-get-tags-for-grid';
import { TagsGridResponse } from '../models/tags-grid-response';
import { tagsSaveTag } from '../fn/tags/tags-save-tag';
import { TagsSaveTag$Params } from '../fn/tags/tags-save-tag';

@Injectable({ providedIn: 'root' })
export class TagsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tagsGetTags()` */
  static readonly TagsGetTagsPath = '/api/tags/list';

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsGetTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsGetTags$Response(params?: TagsGetTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagModel>>> {
    return tagsGetTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsGetTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsGetTags(params?: TagsGetTags$Params, context?: HttpContext): Observable<Array<TagModel>> {
    return this.tagsGetTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagModel>>): Array<TagModel> => r.body)
    );
  }

  /** Path part for operation `tagsGetTagsForGrid()` */
  static readonly TagsGetTagsForGridPath = '/api/tags/grid';

  /**
   * Get List Of Tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsGetTagsForGrid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsGetTagsForGrid$Response(params: TagsGetTagsForGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<TagsGridResponse>> {
    return tagsGetTagsForGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsGetTagsForGrid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsGetTagsForGrid(params: TagsGetTagsForGrid$Params, context?: HttpContext): Observable<TagsGridResponse> {
    return this.tagsGetTagsForGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagsGridResponse>): TagsGridResponse => r.body)
    );
  }

  /** Path part for operation `tagsSaveTag()` */
  static readonly TagsSaveTagPath = '/api/tags/save';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsSaveTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsSaveTag$Response(params: TagsSaveTag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return tagsSaveTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsSaveTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsSaveTag(params: TagsSaveTag$Params, context?: HttpContext): Observable<void> {
    return this.tagsSaveTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `tagsDeleteTagById()` */
  static readonly TagsDeleteTagByIdPath = '/api/tags/tag/{id}';

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsDeleteTagById()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsDeleteTagById$Response(params: TagsDeleteTagById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return tagsDeleteTagById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsDeleteTagById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsDeleteTagById(params: TagsDeleteTagById$Params, context?: HttpContext): Observable<void> {
    return this.tagsDeleteTagById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
