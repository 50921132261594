import {Routes} from '@angular/router';
import {AuthGuard} from './core/security/auth-guard.service';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: '',
    loadChildren: () => import('./cp/cp.module').then(m => m.CpModule),
    canActivateChild: [AuthGuard],
  },
  /*{ path: '**', component: PageNotFoundComponent }*/
];
