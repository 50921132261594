/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MessagingTemplateModel } from '../../models/messaging-template-model';

export interface MessagingTemplateSaveTemplate$Params {
      body: MessagingTemplateModel
}

export function messagingTemplateSaveTemplate(http: HttpClient, rootUrl: string, params: MessagingTemplateSaveTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<MessagingTemplateModel>> {
  const rb = new RequestBuilder(rootUrl, messagingTemplateSaveTemplate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MessagingTemplateModel>;
    })
  );
}

messagingTemplateSaveTemplate.PATH = '/api/messaging-template/item';
