/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accountStorageGetCurrentUsage } from '../fn/account/account-storage-get-current-usage';
import { AccountStorageGetCurrentUsage$Params } from '../fn/account/account-storage-get-current-usage';
import { accountStorageGetRetentionWarning } from '../fn/account/account-storage-get-retention-warning';
import { AccountStorageGetRetentionWarning$Params } from '../fn/account/account-storage-get-retention-warning';
import { AccountStorageModel } from '../models/account-storage-model';
import { accountStorageSaveRecordingsRetention } from '../fn/account/account-storage-save-recordings-retention';
import { AccountStorageSaveRecordingsRetention$Params } from '../fn/account/account-storage-save-recordings-retention';
import { RetentionWarningModel } from '../models/retention-warning-model';

@Injectable({ providedIn: 'root' })
export class StorageManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountStorageGetCurrentUsage()` */
  static readonly AccountStorageGetCurrentUsagePath = '/api/account/current-usage';

  /**
   * Get current storage usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageGetCurrentUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountStorageGetCurrentUsage$Response(params?: AccountStorageGetCurrentUsage$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountStorageModel>> {
    return accountStorageGetCurrentUsage(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current storage usage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageGetCurrentUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountStorageGetCurrentUsage(params?: AccountStorageGetCurrentUsage$Params, context?: HttpContext): Observable<AccountStorageModel> {
    return this.accountStorageGetCurrentUsage$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountStorageModel>): AccountStorageModel => r.body)
    );
  }

  /** Path part for operation `accountStorageSaveRecordingsRetention()` */
  static readonly AccountStorageSaveRecordingsRetentionPath = '/api/account/save-recordings-retention';

  /**
   * Save account recordings retention limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageSaveRecordingsRetention()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageSaveRecordingsRetention$Response(params: AccountStorageSaveRecordingsRetention$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountStorageModel>> {
    return accountStorageSaveRecordingsRetention(this.http, this.rootUrl, params, context);
  }

  /**
   * Save account recordings retention limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageSaveRecordingsRetention$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageSaveRecordingsRetention(params: AccountStorageSaveRecordingsRetention$Params, context?: HttpContext): Observable<AccountStorageModel> {
    return this.accountStorageSaveRecordingsRetention$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountStorageModel>): AccountStorageModel => r.body)
    );
  }

  /** Path part for operation `accountStorageGetRetentionWarning()` */
  static readonly AccountStorageGetRetentionWarningPath = '/api/account/recordings-retention-warning';

  /**
   * Get hours that will be deleted after plan changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageGetRetentionWarning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageGetRetentionWarning$Response(params: AccountStorageGetRetentionWarning$Params, context?: HttpContext): Observable<StrictHttpResponse<RetentionWarningModel>> {
    return accountStorageGetRetentionWarning(this.http, this.rootUrl, params, context);
  }

  /**
   * Get hours that will be deleted after plan changes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageGetRetentionWarning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageGetRetentionWarning(params: AccountStorageGetRetentionWarning$Params, context?: HttpContext): Observable<RetentionWarningModel> {
    return this.accountStorageGetRetentionWarning$Response(params, context).pipe(
      map((r: StrictHttpResponse<RetentionWarningModel>): RetentionWarningModel => r.body)
    );
  }

}
