/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { messagingTemplateDeleteTemplateById } from '../fn/messaging-template/messaging-template-delete-template-by-id';
import { MessagingTemplateDeleteTemplateById$Params } from '../fn/messaging-template/messaging-template-delete-template-by-id';
import { messagingTemplateGetList } from '../fn/messaging-template/messaging-template-get-list';
import { MessagingTemplateGetList$Params } from '../fn/messaging-template/messaging-template-get-list';
import { messagingTemplateGetTemplateById } from '../fn/messaging-template/messaging-template-get-template-by-id';
import { MessagingTemplateGetTemplateById$Params } from '../fn/messaging-template/messaging-template-get-template-by-id';
import { MessagingTemplateModel } from '../models/messaging-template-model';
import { messagingTemplateSaveTemplate } from '../fn/messaging-template/messaging-template-save-template';
import { MessagingTemplateSaveTemplate$Params } from '../fn/messaging-template/messaging-template-save-template';
import { TemplateListResponse } from '../models/template-list-response';

@Injectable({ providedIn: 'root' })
export class MessagingTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingTemplateGetList()` */
  static readonly MessagingTemplateGetListPath = '/api/messaging-template/list';

  /**
   * List message templates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateGetList$Response(params: MessagingTemplateGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateListResponse>> {
    return messagingTemplateGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * List message templates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateGetList(params: MessagingTemplateGetList$Params, context?: HttpContext): Observable<TemplateListResponse> {
    return this.messagingTemplateGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateListResponse>): TemplateListResponse => r.body)
    );
  }

  /** Path part for operation `messagingTemplateGetTemplateById()` */
  static readonly MessagingTemplateGetTemplateByIdPath = '/api/messaging-template/item/{id}';

  /**
   * Get template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateGetTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateGetTemplateById$Response(params: MessagingTemplateGetTemplateById$Params, context?: HttpContext): Observable<StrictHttpResponse<MessagingTemplateModel>> {
    return messagingTemplateGetTemplateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateGetTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateGetTemplateById(params: MessagingTemplateGetTemplateById$Params, context?: HttpContext): Observable<MessagingTemplateModel> {
    return this.messagingTemplateGetTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingTemplateModel>): MessagingTemplateModel => r.body)
    );
  }

  /** Path part for operation `messagingTemplateDeleteTemplateById()` */
  static readonly MessagingTemplateDeleteTemplateByIdPath = '/api/messaging-template/item/{id}';

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateDeleteTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateDeleteTemplateById$Response(params: MessagingTemplateDeleteTemplateById$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return messagingTemplateDeleteTemplateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateDeleteTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateDeleteTemplateById(params: MessagingTemplateDeleteTemplateById$Params, context?: HttpContext): Observable<boolean> {
    return this.messagingTemplateDeleteTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingTemplateSaveTemplate()` */
  static readonly MessagingTemplateSaveTemplatePath = '/api/messaging-template/item';

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateSaveTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateSaveTemplate$Response(params: MessagingTemplateSaveTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<MessagingTemplateModel>> {
    return messagingTemplateSaveTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateSaveTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateSaveTemplate(params: MessagingTemplateSaveTemplate$Params, context?: HttpContext): Observable<MessagingTemplateModel> {
    return this.messagingTemplateSaveTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingTemplateModel>): MessagingTemplateModel => r.body)
    );
  }

}
