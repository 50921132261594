/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accountSettingsGetSettings } from '../fn/account/account-settings-get-settings';
import { AccountSettingsGetSettings$Params } from '../fn/account/account-settings-get-settings';
import { AccountSettingsModel } from '../models/account-settings-model';
import { accountSettingsPreviewFax } from '../fn/account/account-settings-preview-fax';
import { AccountSettingsPreviewFax$Params } from '../fn/account/account-settings-preview-fax';
import { accountSettingsSaveSettings } from '../fn/account/account-settings-save-settings';
import { AccountSettingsSaveSettings$Params } from '../fn/account/account-settings-save-settings';
import { accountSettingsToggleBeta } from '../fn/account/account-settings-toggle-beta';
import { AccountSettingsToggleBeta$Params } from '../fn/account/account-settings-toggle-beta';
import { ToggleBetaResponse } from '../models/toggle-beta-response';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountSettingsGetSettings()` */
  static readonly AccountSettingsGetSettingsPath = '/api/account/settings';

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsGetSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings$Response(params?: AccountSettingsGetSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountSettingsModel>> {
    return accountSettingsGetSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsGetSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings(params?: AccountSettingsGetSettings$Params, context?: HttpContext): Observable<AccountSettingsModel> {
    return this.accountSettingsGetSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsSaveSettings()` */
  static readonly AccountSettingsSaveSettingsPath = '/api/account/settings';

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsSaveSettings()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings$Response(params: AccountSettingsSaveSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountSettingsModel>> {
    return accountSettingsSaveSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsSaveSettings$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings(params: AccountSettingsSaveSettings$Params, context?: HttpContext): Observable<AccountSettingsModel> {
    return this.accountSettingsSaveSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsPreviewFax()` */
  static readonly AccountSettingsPreviewFaxPath = '/api/account/preview-fax-cover/{filename}';

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsPreviewFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax$Response(params: AccountSettingsPreviewFax$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountSettingsPreviewFax(this.http, this.rootUrl, params, context);
  }

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsPreviewFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax(params: AccountSettingsPreviewFax$Params, context?: HttpContext): Observable<void> {
    return this.accountSettingsPreviewFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountSettingsToggleBeta()` */
  static readonly AccountSettingsToggleBetaPath = '/api/account/toggle-beta';

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsToggleBeta()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta$Response(params: AccountSettingsToggleBeta$Params, context?: HttpContext): Observable<StrictHttpResponse<ToggleBetaResponse>> {
    return accountSettingsToggleBeta(this.http, this.rootUrl, params, context);
  }

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsToggleBeta$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta(params: AccountSettingsToggleBeta$Params, context?: HttpContext): Observable<ToggleBetaResponse> {
    return this.accountSettingsToggleBeta$Response(params, context).pipe(
      map((r: StrictHttpResponse<ToggleBetaResponse>): ToggleBetaResponse => r.body)
    );
  }

}
