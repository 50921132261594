/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AutomationModel } from '../models/automation-model';
import { AutomationSettingsResponse } from '../models/automation-settings-response';
import { LookupModel } from '../models/lookup-model';
import { messagingAutomationDeleteAutomationSettingsById } from '../fn/messaging-automation/messaging-automation-delete-automation-settings-by-id';
import { MessagingAutomationDeleteAutomationSettingsById$Params } from '../fn/messaging-automation/messaging-automation-delete-automation-settings-by-id';
import { messagingAutomationGetAutomationList } from '../fn/messaging-automation/messaging-automation-get-automation-list';
import { MessagingAutomationGetAutomationList$Params } from '../fn/messaging-automation/messaging-automation-get-automation-list';
import { messagingAutomationGetAutomationSettingsById } from '../fn/messaging-automation/messaging-automation-get-automation-settings-by-id';
import { MessagingAutomationGetAutomationSettingsById$Params } from '../fn/messaging-automation/messaging-automation-get-automation-settings-by-id';
import { messagingAutomationLookupPhoneNumbers } from '../fn/messaging-automation/messaging-automation-lookup-phone-numbers';
import { MessagingAutomationLookupPhoneNumbers$Params } from '../fn/messaging-automation/messaging-automation-lookup-phone-numbers';
import { messagingAutomationSaveAutomationSetting } from '../fn/messaging-automation/messaging-automation-save-automation-setting';
import { MessagingAutomationSaveAutomationSetting$Params } from '../fn/messaging-automation/messaging-automation-save-automation-setting';

@Injectable({ providedIn: 'root' })
export class MessagingAutomationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingAutomationLookupPhoneNumbers()` */
  static readonly MessagingAutomationLookupPhoneNumbersPath = '/api/messaging-automation/lookup-phone-numbers';

  /**
   * Get all pro sms active phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationLookupPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationLookupPhoneNumbers$Response(params?: MessagingAutomationLookupPhoneNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LookupModel>>> {
    return messagingAutomationLookupPhoneNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all pro sms active phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationLookupPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationLookupPhoneNumbers(params?: MessagingAutomationLookupPhoneNumbers$Params, context?: HttpContext): Observable<Array<LookupModel>> {
    return this.messagingAutomationLookupPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `messagingAutomationGetAutomationList()` */
  static readonly MessagingAutomationGetAutomationListPath = '/api/messaging-automation/list';

  /**
   * List of all messaging automations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationGetAutomationList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationGetAutomationList$Response(params: MessagingAutomationGetAutomationList$Params, context?: HttpContext): Observable<StrictHttpResponse<AutomationSettingsResponse>> {
    return messagingAutomationGetAutomationList(this.http, this.rootUrl, params, context);
  }

  /**
   * List of all messaging automations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationGetAutomationList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationGetAutomationList(params: MessagingAutomationGetAutomationList$Params, context?: HttpContext): Observable<AutomationSettingsResponse> {
    return this.messagingAutomationGetAutomationList$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutomationSettingsResponse>): AutomationSettingsResponse => r.body)
    );
  }

  /** Path part for operation `messagingAutomationGetAutomationSettingsById()` */
  static readonly MessagingAutomationGetAutomationSettingsByIdPath = '/api/messaging-automation/{id}';

  /**
   * Get automation settings details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationGetAutomationSettingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationGetAutomationSettingsById$Response(params: MessagingAutomationGetAutomationSettingsById$Params, context?: HttpContext): Observable<StrictHttpResponse<AutomationModel>> {
    return messagingAutomationGetAutomationSettingsById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get automation settings details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationGetAutomationSettingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationGetAutomationSettingsById(params: MessagingAutomationGetAutomationSettingsById$Params, context?: HttpContext): Observable<AutomationModel> {
    return this.messagingAutomationGetAutomationSettingsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutomationModel>): AutomationModel => r.body)
    );
  }

  /** Path part for operation `messagingAutomationDeleteAutomationSettingsById()` */
  static readonly MessagingAutomationDeleteAutomationSettingsByIdPath = '/api/messaging-automation/{id}';

  /**
   * Delete automation settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationDeleteAutomationSettingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationDeleteAutomationSettingsById$Response(params: MessagingAutomationDeleteAutomationSettingsById$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return messagingAutomationDeleteAutomationSettingsById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete automation settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationDeleteAutomationSettingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationDeleteAutomationSettingsById(params: MessagingAutomationDeleteAutomationSettingsById$Params, context?: HttpContext): Observable<boolean> {
    return this.messagingAutomationDeleteAutomationSettingsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingAutomationSaveAutomationSetting()` */
  static readonly MessagingAutomationSaveAutomationSettingPath = '/api/messaging-automation/save';

  /**
   * Save automation settings details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationSaveAutomationSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationSaveAutomationSetting$Response(params: MessagingAutomationSaveAutomationSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<AutomationModel>> {
    return messagingAutomationSaveAutomationSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * Save automation settings details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationSaveAutomationSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationSaveAutomationSetting(params: MessagingAutomationSaveAutomationSetting$Params, context?: HttpContext): Observable<AutomationModel> {
    return this.messagingAutomationSaveAutomationSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutomationModel>): AutomationModel => r.body)
    );
  }

}
