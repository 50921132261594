import {Inject, Injectable} from '@angular/core';
import {DOCUMENT, Location} from '@angular/common';
import {Router} from '@angular/router';
import {AuthService} from '../security/auth.service';
import {OverlayContainer} from '@angular/cdk/overlay';

@Injectable({providedIn: 'root'})
export class HelpscoutService {

  constructor(@Inject(DOCUMENT) document,
              private location: Location,
              private router: Router,
              private authService: AuthService,
              private overlayContainer: OverlayContainer) {

    console.log('init HelpscoutService');
  }

  init() {
    const beacon = window['Beacon'];
    beacon('init', 'ccdfc831-3d91-483f-bfef-2eca928e696f');
  }

  destroy() {
    const beacon = window['Beacon'];
    beacon('destroy');
  }

  config(color: string) {
    this.destroy();
    this.init();
    const beacon = window['Beacon'];

    beacon('config', {
      color: color, // '#007a99',
      display: {
        position: 'right'
      }
    });

    /*beacon('on', 'ready', () => {
      const elm = document.getElementById('beacon-container');
      if (elm) {
        elm.style.visibility = 'hidden';
      }
    });*/

    beacon('on', 'open', () => {
      console.log('Beacon opened');
      const elm = document.getElementById('beacon-container');
      if (elm) {
        elm.style.visibility = 'visible';
      }
    });

    beacon('on', 'close', () => {
      console.log('Beacon closed');
      const elm = document.getElementById('beacon-container');
      if (elm) {
        elm.style.visibility = 'hidden';
      }
    });
  }

  toggle() {
    const beacon = window['Beacon'];
    beacon('toggle');
  }
}
