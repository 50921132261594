import * as allRoutes from '../routes/all-routes';
import {Injectable} from '@angular/core';

@Injectable()
export class RouterHelper {

  public static allRoutes = allRoutes.routes;

  public static getRouteByComponent(component: string) {
    return this.allRoutes.find(route => route.key === component);
  }
}

export interface RouteHelperItem {
  key: string;
  path: string;
  data: any;
}
