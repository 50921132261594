import {Injectable, OnDestroy} from '@angular/core';
import 'twix';

@Injectable()
export class ChartDefaultsService implements OnDestroy {

  constructor() {

  }

  ngOnDestroy() {
    console.log('DESTROYED THE ReportsService');
  }
}
