/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { contactDeleteContactById } from '../fn/contact/contact-delete-contact-by-id';
import { ContactDeleteContactById$Params } from '../fn/contact/contact-delete-contact-by-id';
import { contactGetContactById } from '../fn/contact/contact-get-contact-by-id';
import { ContactGetContactById$Params } from '../fn/contact/contact-get-contact-by-id';
import { contactGetCustomFieldsDefinitions } from '../fn/contact/contact-get-custom-fields-definitions';
import { ContactGetCustomFieldsDefinitions$Params } from '../fn/contact/contact-get-custom-fields-definitions';
import { contactGetDuplicateUserContacts } from '../fn/contact/contact-get-duplicate-user-contacts';
import { ContactGetDuplicateUserContacts$Params } from '../fn/contact/contact-get-duplicate-user-contacts';
import { contactGetDuplicateUserContactsSummary } from '../fn/contact/contact-get-duplicate-user-contacts-summary';
import { ContactGetDuplicateUserContactsSummary$Params } from '../fn/contact/contact-get-duplicate-user-contacts-summary';
import { contactGetUserContacts } from '../fn/contact/contact-get-user-contacts';
import { ContactGetUserContacts$Params } from '../fn/contact/contact-get-user-contacts';
import { contactGetUserLookupContacts } from '../fn/contact/contact-get-user-lookup-contacts';
import { ContactGetUserLookupContacts$Params } from '../fn/contact/contact-get-user-lookup-contacts';
import { ContactModel } from '../models/contact-model';
import { contactSaveContact } from '../fn/contact/contact-save-contact';
import { ContactSaveContact$Params } from '../fn/contact/contact-save-contact';
import { contactSaveContacts } from '../fn/contact/contact-save-contacts';
import { ContactSaveContacts$Params } from '../fn/contact/contact-save-contacts';
import { ContactsImportResponse } from '../models/contacts-import-response';
import { ContactsResponse } from '../models/contacts-response';
import { contactSyncGetSyncSettings } from '../fn/contact/contact-sync-get-sync-settings';
import { ContactSyncGetSyncSettings$Params } from '../fn/contact/contact-sync-get-sync-settings';
import { contactSyncGoogleCallback } from '../fn/contact/contact-sync-google-callback';
import { ContactSyncGoogleCallback$Params } from '../fn/contact/contact-sync-google-callback';
import { contactSyncImportCsv } from '../fn/contact/contact-sync-import-csv';
import { ContactSyncImportCsv$Params } from '../fn/contact/contact-sync-import-csv';
import { contactSyncMicrosoftCallback } from '../fn/contact/contact-sync-microsoft-callback';
import { ContactSyncMicrosoftCallback$Params } from '../fn/contact/contact-sync-microsoft-callback';
import { ContactSyncModel } from '../models/contact-sync-model';
import { ContactSyncResultsResponse } from '../models/contact-sync-results-response';
import { contactSyncRevokeGoogleToken } from '../fn/contact/contact-sync-revoke-google-token';
import { ContactSyncRevokeGoogleToken$Params } from '../fn/contact/contact-sync-revoke-google-token';
import { contactSyncRevokeMicrosoftToken } from '../fn/contact/contact-sync-revoke-microsoft-token';
import { ContactSyncRevokeMicrosoftToken$Params } from '../fn/contact/contact-sync-revoke-microsoft-token';
import { contactSyncSaveGoogleToken } from '../fn/contact/contact-sync-save-google-token';
import { ContactSyncSaveGoogleToken$Params } from '../fn/contact/contact-sync-save-google-token';
import { contactSyncSaveMicrosoftToken } from '../fn/contact/contact-sync-save-microsoft-token';
import { ContactSyncSaveMicrosoftToken$Params } from '../fn/contact/contact-sync-save-microsoft-token';
import { contactSyncSaveSyncSettings } from '../fn/contact/contact-sync-save-sync-settings';
import { ContactSyncSaveSyncSettings$Params } from '../fn/contact/contact-sync-save-sync-settings';
import { contactSyncSyncPbxContactsByProvider } from '../fn/contact/contact-sync-sync-pbx-contacts-by-provider';
import { ContactSyncSyncPbxContactsByProvider$Params } from '../fn/contact/contact-sync-sync-pbx-contacts-by-provider';
import { CustomFieldModel } from '../models/custom-field-model';
import { DeleteContactResponse } from '../models/delete-contact-response';
import { DuplicateContactGridResponse } from '../models/duplicate-contact-grid-response';
import { DuplicateContactsSummaryResponse } from '../models/duplicate-contacts-summary-response';
import { LookupContactGridResponse } from '../models/lookup-contact-grid-response';

@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactGetUserLookupContacts()` */
  static readonly ContactGetUserLookupContactsPath = '/api/contact/lookup';

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserLookupContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts$Response(params: ContactGetUserLookupContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupContactGridResponse>> {
    return contactGetUserLookupContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserLookupContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts(params: ContactGetUserLookupContacts$Params, context?: HttpContext): Observable<LookupContactGridResponse> {
    return this.contactGetUserLookupContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupContactGridResponse>): LookupContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetUserContacts()` */
  static readonly ContactGetUserContactsPath = '/api/contact/list';

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts$Response(params: ContactGetUserContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactsResponse>> {
    return contactGetUserContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts(params: ContactGetUserContacts$Params, context?: HttpContext): Observable<ContactsResponse> {
    return this.contactGetUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactsResponse>): ContactsResponse => r.body)
    );
  }

  /** Path part for operation `contactGetContactById()` */
  static readonly ContactGetContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById$Response(params: ContactGetContactById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactModel>> {
    return contactGetContactById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById(params: ContactGetContactById$Params, context?: HttpContext): Observable<ContactModel> {
    return this.contactGetContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactDeleteContactById()` */
  static readonly ContactDeleteContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactDeleteContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById$Response(params: ContactDeleteContactById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteContactResponse>> {
    return contactDeleteContactById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactDeleteContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById(params: ContactDeleteContactById$Params, context?: HttpContext): Observable<DeleteContactResponse> {
    return this.contactDeleteContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteContactResponse>): DeleteContactResponse => r.body)
    );
  }

  /** Path part for operation `contactSaveContact()` */
  static readonly ContactSaveContactPath = '/api/contact/save';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact$Response(params: ContactSaveContact$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactModel>> {
    return contactSaveContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact(params: ContactSaveContact$Params, context?: HttpContext): Observable<ContactModel> {
    return this.contactSaveContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactSaveContacts()` */
  static readonly ContactSaveContactsPath = '/api/contact/save-contacts';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts$Response(params: ContactSaveContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return contactSaveContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts(params: ContactSaveContacts$Params, context?: HttpContext): Observable<void> {
    return this.contactSaveContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContactsSummary()` */
  static readonly ContactGetDuplicateUserContactsSummaryPath = '/api/contact/duplicate-summary';

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContactsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary$Response(params?: ContactGetDuplicateUserContactsSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicateContactsSummaryResponse>> {
    return contactGetDuplicateUserContactsSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContactsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary(params?: ContactGetDuplicateUserContactsSummary$Params, context?: HttpContext): Observable<DuplicateContactsSummaryResponse> {
    return this.contactGetDuplicateUserContactsSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactsSummaryResponse>): DuplicateContactsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContacts()` */
  static readonly ContactGetDuplicateUserContactsPath = '/api/contact/duplicate-list';

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts$Response(params: ContactGetDuplicateUserContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicateContactGridResponse>> {
    return contactGetDuplicateUserContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts(params: ContactGetDuplicateUserContacts$Params, context?: HttpContext): Observable<DuplicateContactGridResponse> {
    return this.contactGetDuplicateUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactGridResponse>): DuplicateContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetCustomFieldsDefinitions()` */
  static readonly ContactGetCustomFieldsDefinitionsPath = '/api/contact/custom-fields';

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetCustomFieldsDefinitions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions$Response(params: ContactGetCustomFieldsDefinitions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomFieldModel>>> {
    return contactGetCustomFieldsDefinitions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetCustomFieldsDefinitions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions(params: ContactGetCustomFieldsDefinitions$Params, context?: HttpContext): Observable<Array<CustomFieldModel>> {
    return this.contactGetCustomFieldsDefinitions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomFieldModel>>): Array<CustomFieldModel> => r.body)
    );
  }

  /** Path part for operation `contactSyncGetSyncSettings()` */
  static readonly ContactSyncGetSyncSettingsPath = '/api/contact/sync-settings';

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncGetSyncSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGetSyncSettings$Response(params?: ContactSyncGetSyncSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactSyncModel>>> {
    return contactSyncGetSyncSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncGetSyncSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGetSyncSettings(params?: ContactSyncGetSyncSettings$Params, context?: HttpContext): Observable<Array<ContactSyncModel>> {
    return this.contactSyncGetSyncSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactSyncModel>>): Array<ContactSyncModel> => r.body)
    );
  }

  /** Path part for operation `contactSyncSaveSyncSettings()` */
  static readonly ContactSyncSaveSyncSettingsPath = '/api/contact/sync-settings';

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSaveSyncSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSyncSaveSyncSettings$Response(params: ContactSyncSaveSyncSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return contactSyncSaveSyncSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSaveSyncSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSyncSaveSyncSettings(params: ContactSyncSaveSyncSettings$Params, context?: HttpContext): Observable<boolean> {
    return this.contactSyncSaveSyncSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncRevokeGoogleToken()` */
  static readonly ContactSyncRevokeGoogleTokenPath = '/api/contact/google/revoke';

  /**
   * Revoke google auth token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncRevokeGoogleToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeGoogleToken$Response(params?: ContactSyncRevokeGoogleToken$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return contactSyncRevokeGoogleToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke google auth token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncRevokeGoogleToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeGoogleToken(params?: ContactSyncRevokeGoogleToken$Params, context?: HttpContext): Observable<boolean> {
    return this.contactSyncRevokeGoogleToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncGoogleCallback()` */
  static readonly ContactSyncGoogleCallbackPath = '/api/contact/google/callback';

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncGoogleCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGoogleCallback$Response(params: ContactSyncGoogleCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return contactSyncGoogleCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncGoogleCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGoogleCallback(params: ContactSyncGoogleCallback$Params, context?: HttpContext): Observable<void> {
    return this.contactSyncGoogleCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactSyncSaveGoogleToken()` */
  static readonly ContactSyncSaveGoogleTokenPath = '/api/contact/google/callback-finish';

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSaveGoogleToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveGoogleToken$Response(params: ContactSyncSaveGoogleToken$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return contactSyncSaveGoogleToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSaveGoogleToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveGoogleToken(params: ContactSyncSaveGoogleToken$Params, context?: HttpContext): Observable<boolean> {
    return this.contactSyncSaveGoogleToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncRevokeMicrosoftToken()` */
  static readonly ContactSyncRevokeMicrosoftTokenPath = '/api/contact/microsoft/revoke';

  /**
   * Revoke microsoft auth token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncRevokeMicrosoftToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeMicrosoftToken$Response(params?: ContactSyncRevokeMicrosoftToken$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return contactSyncRevokeMicrosoftToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke microsoft auth token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncRevokeMicrosoftToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeMicrosoftToken(params?: ContactSyncRevokeMicrosoftToken$Params, context?: HttpContext): Observable<boolean> {
    return this.contactSyncRevokeMicrosoftToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncMicrosoftCallback()` */
  static readonly ContactSyncMicrosoftCallbackPath = '/api/contact/microsoft/callback';

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncMicrosoftCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncMicrosoftCallback$Response(params: ContactSyncMicrosoftCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return contactSyncMicrosoftCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncMicrosoftCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncMicrosoftCallback(params: ContactSyncMicrosoftCallback$Params, context?: HttpContext): Observable<void> {
    return this.contactSyncMicrosoftCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactSyncSaveMicrosoftToken()` */
  static readonly ContactSyncSaveMicrosoftTokenPath = '/api/contact/microsoft/callback-finish';

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSaveMicrosoftToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveMicrosoftToken$Response(params: ContactSyncSaveMicrosoftToken$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return contactSyncSaveMicrosoftToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSaveMicrosoftToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveMicrosoftToken(params: ContactSyncSaveMicrosoftToken$Params, context?: HttpContext): Observable<boolean> {
    return this.contactSyncSaveMicrosoftToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncImportCsv()` */
  static readonly ContactSyncImportCsvPath = '/api/contact/import-csv';

  /**
   * Import Contacts from CSV file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncImportCsv()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactSyncImportCsv$Response(params: ContactSyncImportCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactsImportResponse>> {
    return contactSyncImportCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * Import Contacts from CSV file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncImportCsv$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactSyncImportCsv(params: ContactSyncImportCsv$Params, context?: HttpContext): Observable<ContactsImportResponse> {
    return this.contactSyncImportCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactsImportResponse>): ContactsImportResponse => r.body)
    );
  }

  /** Path part for operation `contactSyncSyncPbxContactsByProvider()` */
  static readonly ContactSyncSyncPbxContactsByProviderPath = '/api/contact/pbx/sync-contacts/{provider}';

  /**
   * Sync Contacts Manually.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSyncPbxContactsByProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSyncPbxContactsByProvider$Response(params: ContactSyncSyncPbxContactsByProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactSyncResultsResponse>> {
    return contactSyncSyncPbxContactsByProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync Contacts Manually.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSyncPbxContactsByProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSyncPbxContactsByProvider(params: ContactSyncSyncPbxContactsByProvider$Params, context?: HttpContext): Observable<ContactSyncResultsResponse> {
    return this.contactSyncSyncPbxContactsByProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactSyncResultsResponse>): ContactSyncResultsResponse => r.body)
    );
  }

}
