import { PreloadAllModules, RouterModule } from '@angular/router';
import {appRoutes} from './app.routes';
import {NgModule} from '@angular/core';
import { DelayedPreloadingStrategy } from './core/preload-strategies/delayed-preloading-strategy';

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        useHash: true,
        // preloadingStrategy: QuicklinkStrategy,
        // preloadingStrategy: PreloadAllModules,
        // preloadingStrategy: SelectivePreloadingStrategyService,
        preloadingStrategy: DelayedPreloadingStrategy,
      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    DelayedPreloadingStrategy,
  ]
})
export class AppRoutingModule {
}


