import {Inject, Injectable} from '@angular/core';
import {DOCUMENT, Location} from '@angular/common';
import {Router} from '@angular/router';
import {AuthService} from '../security/auth.service';

@Injectable({providedIn: 'root'})
export class ZendeskService {

  isOpen = false;

  constructor(@Inject(DOCUMENT) document,
              private location: Location,
              private router: Router,
              private authService: AuthService) {

    console.log('init ZendeskService');
  }

  close() {
    const widget = window['zE'];
    if (widget) {
      widget(() => {
        const zE = window['zE'];
        zE.hide();
        this.isOpen = false;
      });
    }
  }

  config(color: string) {
    console.log('reset zendesk');
    this.close();

    const widget = window['zE'];

    widget('webWidget', 'updateSettings', {
      webWidget: {
        color: { theme: color }
      }
    });

    widget('webWidget:on', 'close', () => {
      console.log('The widget has been closed!');
      this.isOpen = false;
    });

    widget('webWidget:on', 'open', () => {
      console.log('The widget has been opened!');
      this.isOpen = true;
    });
  }

  toggle() {
    const widget = window['zE'];
    if (this.isOpen) {
      this.isOpen = false;
      widget.hide();
    } else {
      this.isOpen = true;
      const currentUser = this.authService.currentUser.getValue();

      widget.identify({
        name: currentUser?.displayName,
        email: currentUser?.username,
        organization: currentUser?.accounts[0]
      });

      widget.show();
      widget.activate({hideOnClose: true});
    }
  }

  show() {
    const widget = window['zE'];
    this.isOpen = true;
    const currentUser = this.authService.currentUser.getValue();

    widget.identify({
      name: currentUser?.displayName,
      email: currentUser?.username,
      organization: currentUser?.accounts[0]
    });

    widget.show();
    widget.activate({hideOnClose: true});
  }
}
