/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ButtonModel } from '../../models/button-model';

export interface PbxAccountButtonsSaveAccountDeviceButtons$Params {
  id: string;
  
    /**
     * Save Account Buttons
     */
    body: Array<ButtonModel>
}

export function pbxAccountButtonsSaveAccountDeviceButtons(http: HttpClient, rootUrl: string, params: PbxAccountButtonsSaveAccountDeviceButtons$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, pbxAccountButtonsSaveAccountDeviceButtons.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

pbxAccountButtonsSaveAccountDeviceButtons.PATH = '/api/pbx-account-buttons/save-account-device-buttons/{id}';
