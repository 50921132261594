/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { messagingWhatsAppTemplateDeleteTemplateById } from '../fn/messaging-whats-app-template/messaging-whats-app-template-delete-template-by-id';
import { MessagingWhatsAppTemplateDeleteTemplateById$Params } from '../fn/messaging-whats-app-template/messaging-whats-app-template-delete-template-by-id';
import { messagingWhatsAppTemplateGetList } from '../fn/messaging-whats-app-template/messaging-whats-app-template-get-list';
import { MessagingWhatsAppTemplateGetList$Params } from '../fn/messaging-whats-app-template/messaging-whats-app-template-get-list';
import { messagingWhatsAppTemplateGetTemplateById } from '../fn/messaging-whats-app-template/messaging-whats-app-template-get-template-by-id';
import { MessagingWhatsAppTemplateGetTemplateById$Params } from '../fn/messaging-whats-app-template/messaging-whats-app-template-get-template-by-id';
import { messagingWhatsAppTemplateSaveTemplate } from '../fn/messaging-whats-app-template/messaging-whats-app-template-save-template';
import { MessagingWhatsAppTemplateSaveTemplate$Params } from '../fn/messaging-whats-app-template/messaging-whats-app-template-save-template';
import { WhatsAppMessagingTemplateModel } from '../models/whats-app-messaging-template-model';
import { WhatsAppTemplateListResponse } from '../models/whats-app-template-list-response';

@Injectable({ providedIn: 'root' })
export class MessagingWhatsAppTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingWhatsAppTemplateGetList()` */
  static readonly MessagingWhatsAppTemplateGetListPath = '/api/messaging-whats-app-template/list';

  /**
   * List WhatsApp message templates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateGetList$Response(params: MessagingWhatsAppTemplateGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<WhatsAppTemplateListResponse>> {
    return messagingWhatsAppTemplateGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * List WhatsApp message templates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateGetList(params: MessagingWhatsAppTemplateGetList$Params, context?: HttpContext): Observable<WhatsAppTemplateListResponse> {
    return this.messagingWhatsAppTemplateGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhatsAppTemplateListResponse>): WhatsAppTemplateListResponse => r.body)
    );
  }

  /** Path part for operation `messagingWhatsAppTemplateGetTemplateById()` */
  static readonly MessagingWhatsAppTemplateGetTemplateByIdPath = '/api/messaging-whats-app-template/item/{id}';

  /**
   * Get template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateGetTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateGetTemplateById$Response(params: MessagingWhatsAppTemplateGetTemplateById$Params, context?: HttpContext): Observable<StrictHttpResponse<WhatsAppMessagingTemplateModel>> {
    return messagingWhatsAppTemplateGetTemplateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateGetTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateGetTemplateById(params: MessagingWhatsAppTemplateGetTemplateById$Params, context?: HttpContext): Observable<WhatsAppMessagingTemplateModel> {
    return this.messagingWhatsAppTemplateGetTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhatsAppMessagingTemplateModel>): WhatsAppMessagingTemplateModel => r.body)
    );
  }

  /** Path part for operation `messagingWhatsAppTemplateDeleteTemplateById()` */
  static readonly MessagingWhatsAppTemplateDeleteTemplateByIdPath = '/api/messaging-whats-app-template/item/{id}';

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateDeleteTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateDeleteTemplateById$Response(params: MessagingWhatsAppTemplateDeleteTemplateById$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return messagingWhatsAppTemplateDeleteTemplateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateDeleteTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateDeleteTemplateById(params: MessagingWhatsAppTemplateDeleteTemplateById$Params, context?: HttpContext): Observable<boolean> {
    return this.messagingWhatsAppTemplateDeleteTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingWhatsAppTemplateSaveTemplate()` */
  static readonly MessagingWhatsAppTemplateSaveTemplatePath = '/api/messaging-whats-app-template/item';

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateSaveTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateSaveTemplate$Response(params: MessagingWhatsAppTemplateSaveTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<WhatsAppMessagingTemplateModel>> {
    return messagingWhatsAppTemplateSaveTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateSaveTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateSaveTemplate(params: MessagingWhatsAppTemplateSaveTemplate$Params, context?: HttpContext): Observable<WhatsAppMessagingTemplateModel> {
    return this.messagingWhatsAppTemplateSaveTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhatsAppMessagingTemplateModel>): WhatsAppMessagingTemplateModel => r.body)
    );
  }

}
