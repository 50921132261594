import {CountryCode, ParsedNumber, parseIncompletePhoneNumber, parseNumber, format} from 'libphonenumber-js';

export class PhoneNumbersUtils {

  public static equal(phoneNumber: string, otherPhoneNumber: string): boolean {
    return PhoneNumbersUtils.normalize(phoneNumber) === PhoneNumbersUtils.normalize(otherPhoneNumber);
  }

  public static normalize(phoneNumber): string {

    if (phoneNumber.indexOf('+') !== 0 && phoneNumber.indexOf('1') !== 0) {
      phoneNumber = '1' + phoneNumber;
    }

    if (phoneNumber.indexOf('+') !== 0) {
      phoneNumber = '+' + phoneNumber;
    }

    console.log('normalized phone number: ' + phoneNumber);
    return phoneNumber;
  }

  public static phoneNumberToNational(value, country: CountryCode = 'US'): string {

    const phoneNumber = parseNumber(value, {defaultCountry: country, extended: true}) as ParsedNumber;

    if (phoneNumber.valid) {
      if (phoneNumber.country === country || phoneNumber.countryCallingCode === '1') {
        return phoneNumber.phone as string;
      }
      return `+${phoneNumber.countryCallingCode}${phoneNumber.phone}`;
    }

    return parseIncompletePhoneNumber(value);
  }

  public static phoneNumberToInternational(value, country: CountryCode = 'US') {

    const phoneNumber = parseNumber(value, {defaultCountry: country, extended: true}) as ParsedNumber;

    if (phoneNumber.valid) {
      return `+${phoneNumber.countryCallingCode}${phoneNumber.phone}`;
    }

    return value;
  }

  public static format(value, country: CountryCode = 'US') {

    if (!value) {
      return value;
    }

    const phoneNumber = parseNumber(value, {defaultCountry: country, extended: true}) as ParsedNumber;

    if (phoneNumber.valid) {
      if (phoneNumber.country === 'US' || phoneNumber.countryCallingCode === '1') {
        return format(phoneNumber, 'NATIONAL');
      } else {
        return format(phoneNumber, 'INTERNATIONAL');
      }
    }

    return value;
  }

  public static isShortCode(value: string) {
    const number = (value || '');

    if (number.startsWith('+')) {
      return false;
    }

    const shortCodeLength = number.length;

    return shortCodeLength >= 3 && shortCodeLength <= 6;
  }
}

