/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { yealinkLogin } from '../fn/yealink/yealink-login';
import { YealinkLogin$Params } from '../fn/yealink/yealink-login';
import { yealinkLoginScreen } from '../fn/yealink/yealink-login-screen';
import { YealinkLoginScreen$Params } from '../fn/yealink/yealink-login-screen';
import { yealinkLogout } from '../fn/yealink/yealink-logout';
import { YealinkLogout$Params } from '../fn/yealink/yealink-logout';
import { yealinkLogoutConfirm } from '../fn/yealink/yealink-logout-confirm';
import { YealinkLogoutConfirm$Params } from '../fn/yealink/yealink-logout-confirm';

@Injectable({ providedIn: 'root' })
export class YealinkService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `yealinkLoginScreen()` */
  static readonly YealinkLoginScreenPath = '/api/yealink/login-screen/{accountId}';

  /**
   * Get login button configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yealinkLoginScreen()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLoginScreen$Response(params: YealinkLoginScreen$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return yealinkLoginScreen(this.http, this.rootUrl, params, context);
  }

  /**
   * Get login button configuration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yealinkLoginScreen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLoginScreen(params: YealinkLoginScreen$Params, context?: HttpContext): Observable<void> {
    return this.yealinkLoginScreen$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `yealinkLogin()` */
  static readonly YealinkLoginPath = '/api/yealink/login/{accountId}';

  /**
   * Get login button configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yealinkLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLogin$Response(params: YealinkLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return yealinkLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get login button configuration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yealinkLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLogin(params: YealinkLogin$Params, context?: HttpContext): Observable<void> {
    return this.yealinkLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `yealinkLogoutConfirm()` */
  static readonly YealinkLogoutConfirmPath = '/api/yealink/logout-confirm/{accountId}';

  /**
   * Reset Phone after successful login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yealinkLogoutConfirm()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLogoutConfirm$Response(params: YealinkLogoutConfirm$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return yealinkLogoutConfirm(this.http, this.rootUrl, params, context);
  }

  /**
   * Reset Phone after successful login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yealinkLogoutConfirm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLogoutConfirm(params: YealinkLogoutConfirm$Params, context?: HttpContext): Observable<void> {
    return this.yealinkLogoutConfirm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `yealinkLogout()` */
  static readonly YealinkLogoutPath = '/api/yealink/logout/{accountId}';

  /**
   * Reset Phone after successful login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `yealinkLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLogout$Response(params: YealinkLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return yealinkLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * Reset Phone after successful login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `yealinkLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  yealinkLogout(params: YealinkLogout$Params, context?: HttpContext): Observable<void> {
    return this.yealinkLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
