import { Component, OnInit } from '@angular/core';
import { ActivationStart, NavigationEnd, NavigationError, Router } from '@angular/router';
import { AuthService } from './core/security/auth.service';
import { GoogleAnalyticsService } from './analytics/google-analytics.service';
import { MatIconRegistry } from '@angular/material/icon';
import { VersionCheckerService } from './core/services/version-checker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    public matIconRegistry: MatIconRegistry,
    private router: Router,
    private authService: AuthService,
    private versionChecker: VersionCheckerService,
    private gaService: GoogleAnalyticsService,
  ) {
    //this.matIconRegistry.registerFontClassAlias('fas');
    this.router.events.subscribe(event => {
      if (event.constructor.name === 'ActivationStart') {
        const e = event as ActivationStart;

        if (e.snapshot.data) {
          this.authService.currentMenuGroup.next(e.snapshot.data['menuGroup']);
        }
      } else if (event instanceof NavigationEnd) {
        this.gaService.navigateTo(event);
      } else if (event instanceof NavigationError) {
        console.error(event.error);
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(event.error.message)) {
          window.location.reload();
        }
      }
    });

    this.versionChecker.initCheckForUpdates('version.json');
  }

  ngOnInit() {
  }
}
