/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityDashboardGrandTotalsResponse } from '../models/activity-dashboard-grand-totals-response';
import { ActivityDashboardResponse } from '../models/activity-dashboard-response';
import { ActivityDashboardTimeSeriesTotalsResponse } from '../models/activity-dashboard-time-series-totals-response';
import { ActivityDashboardTopByAgentsResponse } from '../models/activity-dashboard-top-by-agents-response';
import { ActivityDashboardTopByGroupsResponse } from '../models/activity-dashboard-top-by-groups-response';
import { activityDashboardV2GetActivityDashboardData } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-data';
import { ActivityDashboardV2GetActivityDashboardData$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-data';
import { activityDashboardV2GetActivityDashboardGrandTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-grand-totals';
import { ActivityDashboardV2GetActivityDashboardGrandTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-grand-totals';
import { activityDashboardV2GetActivityDashboardTimeSeriesTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-time-series-totals';
import { ActivityDashboardV2GetActivityDashboardTimeSeriesTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-time-series-totals';
import { activityDashboardV2GetActivityDashboardTopByAgentTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-agent-totals';
import { ActivityDashboardV2GetActivityDashboardTopByAgentTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-agent-totals';
import { activityDashboardV2GetActivityDashboardTopByGroupTotals } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-group-totals';
import { ActivityDashboardV2GetActivityDashboardTopByGroupTotals$Params } from '../fn/reports-v-2/activity-dashboard-v-2-get-activity-dashboard-top-by-group-totals';
import { CallLogModel } from '../models/call-log-model';
import { CallLogsResponse } from '../models/call-logs-response';
import { callLogsV2GetCallFlowDetails } from '../fn/reports-v-2/call-logs-v-2-get-call-flow-details';
import { CallLogsV2GetCallFlowDetails$Params } from '../fn/reports-v-2/call-logs-v-2-get-call-flow-details';
import { callLogsV2GetCallLogs } from '../fn/reports-v-2/call-logs-v-2-get-call-logs';
import { CallLogsV2GetCallLogs$Params } from '../fn/reports-v-2/call-logs-v-2-get-call-logs';
import { callLogsV2GetExport } from '../fn/reports-v-2/call-logs-v-2-get-export';
import { CallLogsV2GetExport$Params } from '../fn/reports-v-2/call-logs-v-2-get-export';
import { callLogsV2GetRawCallDetails } from '../fn/reports-v-2/call-logs-v-2-get-raw-call-details';
import { CallLogsV2GetRawCallDetails$Params } from '../fn/reports-v-2/call-logs-v-2-get-raw-call-details';
import { GroupAgentsSummaryResponse } from '../models/group-agents-summary-response';
import { GroupsSummaryResponse } from '../models/groups-summary-response';
import { groupsSummaryV2GetAgentsSummaryData } from '../fn/reports-v-2/groups-summary-v-2-get-agents-summary-data';
import { GroupsSummaryV2GetAgentsSummaryData$Params } from '../fn/reports-v-2/groups-summary-v-2-get-agents-summary-data';
import { groupsSummaryV2GetExport } from '../fn/reports-v-2/groups-summary-v-2-get-export';
import { GroupsSummaryV2GetExport$Params } from '../fn/reports-v-2/groups-summary-v-2-get-export';
import { groupsSummaryV2GetExportAgents } from '../fn/reports-v-2/groups-summary-v-2-get-export-agents';
import { GroupsSummaryV2GetExportAgents$Params } from '../fn/reports-v-2/groups-summary-v-2-get-export-agents';
import { groupsSummaryV2GetSummaryData } from '../fn/reports-v-2/groups-summary-v-2-get-summary-data';
import { GroupsSummaryV2GetSummaryData$Params } from '../fn/reports-v-2/groups-summary-v-2-get-summary-data';
import { RawCallModel } from '../models/raw-call-model';
import { UsersSummaryResponse } from '../models/users-summary-response';
import { usersSummaryV2GetExport } from '../fn/reports-v-2/users-summary-v-2-get-export';
import { UsersSummaryV2GetExport$Params } from '../fn/reports-v-2/users-summary-v-2-get-export';
import { usersSummaryV2GetSummaryData } from '../fn/reports-v-2/users-summary-v-2-get-summary-data';
import { UsersSummaryV2GetSummaryData$Params } from '../fn/reports-v-2/users-summary-v-2-get-summary-data';

@Injectable({ providedIn: 'root' })
export class ReportsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `callLogsV2GetCallLogs()` */
  static readonly CallLogsV2GetCallLogsPath = '/api/reports-v2/call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs$Response(params: CallLogsV2GetCallLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<CallLogsResponse>> {
    return callLogsV2GetCallLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs(params: CallLogsV2GetCallLogs$Params, context?: HttpContext): Observable<CallLogsResponse> {
    return this.callLogsV2GetCallLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogsResponse>): CallLogsResponse => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetExport()` */
  static readonly CallLogsV2GetExportPath = '/api/reports-v2/call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport$Response(params: CallLogsV2GetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return callLogsV2GetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport(params: CallLogsV2GetExport$Params, context?: HttpContext): Observable<string> {
    return this.callLogsV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetRawCallDetails()` */
  static readonly CallLogsV2GetRawCallDetailsPath = '/api/reports-v2/raw-call-details';

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetRawCallDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails$Response(params: CallLogsV2GetRawCallDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<RawCallModel>> {
    return callLogsV2GetRawCallDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetRawCallDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails(params: CallLogsV2GetRawCallDetails$Params, context?: HttpContext): Observable<RawCallModel> {
    return this.callLogsV2GetRawCallDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<RawCallModel>): RawCallModel => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetCallFlowDetails()` */
  static readonly CallLogsV2GetCallFlowDetailsPath = '/api/reports-v2/call-flow-detail';

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallFlowDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails$Response(params: CallLogsV2GetCallFlowDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<CallLogModel>> {
    return callLogsV2GetCallFlowDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallFlowDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails(params: CallLogsV2GetCallFlowDetails$Params, context?: HttpContext): Observable<CallLogModel> {
    return this.callLogsV2GetCallFlowDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogModel>): CallLogModel => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetSummaryData()` */
  static readonly GroupsSummaryV2GetSummaryDataPath = '/api/reports-v2/groups-summary-data';

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData$Response(params: GroupsSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupsSummaryResponse>> {
    return groupsSummaryV2GetSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData(params: GroupsSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<GroupsSummaryResponse> {
    return this.groupsSummaryV2GetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsSummaryResponse>): GroupsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetAgentsSummaryData()` */
  static readonly GroupsSummaryV2GetAgentsSummaryDataPath = '/api/reports-v2/groups-agent-summary-data';

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetAgentsSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData$Response(params: GroupsSummaryV2GetAgentsSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupAgentsSummaryResponse>> {
    return groupsSummaryV2GetAgentsSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetAgentsSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData(params: GroupsSummaryV2GetAgentsSummaryData$Params, context?: HttpContext): Observable<GroupAgentsSummaryResponse> {
    return this.groupsSummaryV2GetAgentsSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupAgentsSummaryResponse>): GroupAgentsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExport()` */
  static readonly GroupsSummaryV2GetExportPath = '/api/reports-v2/groups-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport$Response(params: GroupsSummaryV2GetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return groupsSummaryV2GetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport(params: GroupsSummaryV2GetExport$Params, context?: HttpContext): Observable<string> {
    return this.groupsSummaryV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExportAgents()` */
  static readonly GroupsSummaryV2GetExportAgentsPath = '/api/reports-v2/groups-summary/export-agents';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExportAgents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents$Response(params: GroupsSummaryV2GetExportAgents$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return groupsSummaryV2GetExportAgents(this.http, this.rootUrl, params, context);
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExportAgents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents(params: GroupsSummaryV2GetExportAgents$Params, context?: HttpContext): Observable<string> {
    return this.groupsSummaryV2GetExportAgents$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersSummaryV2GetSummaryData()` */
  static readonly UsersSummaryV2GetSummaryDataPath = '/api/reports-v2/users-summary';

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryV2GetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetSummaryData$Response(params: UsersSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<UsersSummaryResponse>> {
    return usersSummaryV2GetSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryV2GetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetSummaryData(params: UsersSummaryV2GetSummaryData$Params, context?: HttpContext): Observable<UsersSummaryResponse> {
    return this.usersSummaryV2GetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UsersSummaryResponse>): UsersSummaryResponse => r.body)
    );
  }

  /** Path part for operation `usersSummaryV2GetExport()` */
  static readonly UsersSummaryV2GetExportPath = '/api/reports-v2/users-summary/export';

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetExport$Response(params: UsersSummaryV2GetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return usersSummaryV2GetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetExport(params: UsersSummaryV2GetExport$Params, context?: HttpContext): Observable<string> {
    return this.usersSummaryV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardData()` */
  static readonly ActivityDashboardV2GetActivityDashboardDataPath = '/api/reports-v2/activity-dashboard-data';

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData$Response(params: ActivityDashboardV2GetActivityDashboardData$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardResponse>> {
    return activityDashboardV2GetActivityDashboardData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData(params: ActivityDashboardV2GetActivityDashboardData$Params, context?: HttpContext): Observable<ActivityDashboardResponse> {
    return this.activityDashboardV2GetActivityDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardResponse>): ActivityDashboardResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardGrandTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardGrandTotalsPath = '/api/reports-v2/activity-dashboard-grand-totals';

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardGrandTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals$Response(params: ActivityDashboardV2GetActivityDashboardGrandTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardGrandTotalsResponse>> {
    return activityDashboardV2GetActivityDashboardGrandTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardGrandTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals(params: ActivityDashboardV2GetActivityDashboardGrandTotals$Params, context?: HttpContext): Observable<ActivityDashboardGrandTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardGrandTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardGrandTotalsResponse>): ActivityDashboardGrandTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTimeSeriesTotalsPath = '/api/reports-v2/activity-dashboard-time-series-totals';

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(params: ActivityDashboardV2GetActivityDashboardTimeSeriesTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>> {
    return activityDashboardV2GetActivityDashboardTimeSeriesTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals(params: ActivityDashboardV2GetActivityDashboardTimeSeriesTotals$Params, context?: HttpContext): Observable<ActivityDashboardTimeSeriesTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>): ActivityDashboardTimeSeriesTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByGroupTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByGroupTotalsPath = '/api/reports-v2/activity-dashboard-top-groups';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByGroupTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(params: ActivityDashboardV2GetActivityDashboardTopByGroupTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardTopByGroupsResponse>> {
    return activityDashboardV2GetActivityDashboardTopByGroupTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByGroupTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals(params: ActivityDashboardV2GetActivityDashboardTopByGroupTotals$Params, context?: HttpContext): Observable<ActivityDashboardTopByGroupsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByGroupsResponse>): ActivityDashboardTopByGroupsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByAgentTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByAgentTotalsPath = '/api/reports-v2/activity-dashboard-top-agents';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByAgentTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(params: ActivityDashboardV2GetActivityDashboardTopByAgentTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDashboardTopByAgentsResponse>> {
    return activityDashboardV2GetActivityDashboardTopByAgentTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByAgentTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals(params: ActivityDashboardV2GetActivityDashboardTopByAgentTotals$Params, context?: HttpContext): Observable<ActivityDashboardTopByAgentsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByAgentsResponse>): ActivityDashboardTopByAgentsResponse => r.body)
    );
  }

}
