/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { publicGetCheckRecordings } from '../fn/public/public-get-check-recordings';
import { PublicGetCheckRecordings$Params } from '../fn/public/public-get-check-recordings';
import { publicGetSimpleStatus } from '../fn/public/public-get-simple-status';
import { PublicGetSimpleStatus$Params } from '../fn/public/public-get-simple-status';
import { publicGetStatus } from '../fn/public/public-get-status';
import { PublicGetStatus$Params } from '../fn/public/public-get-status';
import { publicPbxProvisioning } from '../fn/public/public-pbx-provisioning';
import { PublicPbxProvisioning$Params } from '../fn/public/public-pbx-provisioning';
import { publicSendBusinessReferral } from '../fn/public/public-send-business-referral';
import { PublicSendBusinessReferral$Params } from '../fn/public/public-send-business-referral';
import { publicTriggerScheduler } from '../fn/public/public-trigger-scheduler';
import { PublicTriggerScheduler$Params } from '../fn/public/public-trigger-scheduler';

@Injectable({ providedIn: 'root' })
export class PublicService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `publicGetCheckRecordings()` */
  static readonly PublicGetCheckRecordingsPath = '/api/public/check-temp';

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicGetCheckRecordings()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetCheckRecordings$Response(params?: PublicGetCheckRecordings$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicGetCheckRecordings(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicGetCheckRecordings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetCheckRecordings(params?: PublicGetCheckRecordings$Params, context?: HttpContext): Observable<{
}> {
    return this.publicGetCheckRecordings$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicGetSimpleStatus()` */
  static readonly PublicGetSimpleStatusPath = '/api/public/status';

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicGetSimpleStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetSimpleStatus$Response(params?: PublicGetSimpleStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicGetSimpleStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicGetSimpleStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetSimpleStatus(params?: PublicGetSimpleStatus$Params, context?: HttpContext): Observable<{
}> {
    return this.publicGetSimpleStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicGetStatus()` */
  static readonly PublicGetStatusPath = '/api/public/health';

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicGetStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetStatus$Response(params?: PublicGetStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicGetStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicGetStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetStatus(params?: PublicGetStatus$Params, context?: HttpContext): Observable<{
}> {
    return this.publicGetStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicSendBusinessReferral()` */
  static readonly PublicSendBusinessReferralPath = '/api/public/business-referral';

  /**
   * Send business referral email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicSendBusinessReferral()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicSendBusinessReferral$Response(params: PublicSendBusinessReferral$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publicSendBusinessReferral(this.http, this.rootUrl, params, context);
  }

  /**
   * Send business referral email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicSendBusinessReferral$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicSendBusinessReferral(params: PublicSendBusinessReferral$Params, context?: HttpContext): Observable<void> {
    return this.publicSendBusinessReferral$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `publicPbxProvisioning()` */
  static readonly PublicPbxProvisioningPath = '/api/public/prov';

  /**
   * Soft Phone provisioning.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicPbxProvisioning()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicPbxProvisioning$Response(params: PublicPbxProvisioning$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicPbxProvisioning(this.http, this.rootUrl, params, context);
  }

  /**
   * Soft Phone provisioning.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicPbxProvisioning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicPbxProvisioning(params: PublicPbxProvisioning$Params, context?: HttpContext): Observable<{
}> {
    return this.publicPbxProvisioning$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicTriggerScheduler()` */
  static readonly PublicTriggerSchedulerPath = '/api/public/trigger-reports-scheduler';

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicTriggerScheduler()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicTriggerScheduler$Response(params?: PublicTriggerScheduler$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicTriggerScheduler(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicTriggerScheduler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicTriggerScheduler(params?: PublicTriggerScheduler$Params, context?: HttpContext): Observable<{
}> {
    return this.publicTriggerScheduler$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
