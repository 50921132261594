/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { lookupCustomerDomains } from '../fn/lookup/lookup-customer-domains';
import { LookupCustomerDomains$Params } from '../fn/lookup/lookup-customer-domains';
import { lookupGetCustomerExtensions } from '../fn/lookup/lookup-get-customer-extensions';
import { LookupGetCustomerExtensions$Params } from '../fn/lookup/lookup-get-customer-extensions';
import { lookupGetCustomerUserEmailDomains } from '../fn/lookup/lookup-get-customer-user-email-domains';
import { LookupGetCustomerUserEmailDomains$Params } from '../fn/lookup/lookup-get-customer-user-email-domains';
import { lookupGetCustomerUserEmails } from '../fn/lookup/lookup-get-customer-user-emails';
import { LookupGetCustomerUserEmails$Params } from '../fn/lookup/lookup-get-customer-user-emails';
import { lookupGetCustomerUsers } from '../fn/lookup/lookup-get-customer-users';
import { LookupGetCustomerUsers$Params } from '../fn/lookup/lookup-get-customer-users';
import { lookupGetRoles } from '../fn/lookup/lookup-get-roles';
import { LookupGetRoles$Params } from '../fn/lookup/lookup-get-roles';
import { lookupGetSmsNumbers } from '../fn/lookup/lookup-get-sms-numbers';
import { LookupGetSmsNumbers$Params } from '../fn/lookup/lookup-get-sms-numbers';
import { LookupGridResponse } from '../models/lookup-grid-response';
import { lookupLookupContactsAndTags } from '../fn/lookup/lookup-lookup-contacts-and-tags';
import { LookupLookupContactsAndTags$Params } from '../fn/lookup/lookup-lookup-contacts-and-tags';

@Injectable({ providedIn: 'root' })
export class LookupService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lookupGetRoles()` */
  static readonly LookupGetRolesPath = '/api/lookup/roles';

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetRoles$Response(params?: LookupGetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return lookupGetRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetRoles(params?: LookupGetRoles$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.lookupGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerUsers()` */
  static readonly LookupGetCustomerUsersPath = '/api/lookup/customer-users';

  /**
   * Get available customer users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUsers$Response(params?: LookupGetCustomerUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return lookupGetCustomerUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available customer users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUsers(params?: LookupGetCustomerUsers$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.lookupGetCustomerUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerUserEmails()` */
  static readonly LookupGetCustomerUserEmailsPath = '/api/lookup/customer-user-emails';

  /**
   * Get available customer users emails.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerUserEmails()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmails$Response(params?: LookupGetCustomerUserEmails$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return lookupGetCustomerUserEmails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available customer users emails.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerUserEmails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmails(params?: LookupGetCustomerUserEmails$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.lookupGetCustomerUserEmails$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerUserEmailDomains()` */
  static readonly LookupGetCustomerUserEmailDomainsPath = '/api/lookup/customer-user-email-domains';

  /**
   * Get available customer users email domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerUserEmailDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmailDomains$Response(params?: LookupGetCustomerUserEmailDomains$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return lookupGetCustomerUserEmailDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available customer users email domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerUserEmailDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmailDomains(params?: LookupGetCustomerUserEmailDomains$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.lookupGetCustomerUserEmailDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerExtensions()` */
  static readonly LookupGetCustomerExtensionsPath = '/api/lookup/pbx-accounts';

  /**
   * Get pbx accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerExtensions()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerExtensions$Response(params?: LookupGetCustomerExtensions$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return lookupGetCustomerExtensions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get pbx accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerExtensions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerExtensions(params?: LookupGetCustomerExtensions$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.lookupGetCustomerExtensions$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetSmsNumbers()` */
  static readonly LookupGetSmsNumbersPath = '/api/lookup/extension-sms-numbers';

  /**
   * Get sms numbers for pbx extension.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetSmsNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetSmsNumbers$Response(params: LookupGetSmsNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return lookupGetSmsNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get sms numbers for pbx extension.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetSmsNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetSmsNumbers(params: LookupGetSmsNumbers$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.lookupGetSmsNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupLookupContactsAndTags()` */
  static readonly LookupLookupContactsAndTagsPath = '/api/lookup/lookup-contacts';

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupLookupContactsAndTags$Response(params: LookupLookupContactsAndTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    return lookupLookupContactsAndTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupLookupContactsAndTags(params: LookupLookupContactsAndTags$Params, context?: HttpContext): Observable<Array<ContactTagLookupModel>> {
    return this.lookupLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

  /** Path part for operation `lookupCustomerDomains()` */
  static readonly LookupCustomerDomainsPath = '/api/lookup/lookup-domains';

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupCustomerDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCustomerDomains$Response(params?: LookupCustomerDomains$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupGridResponse>> {
    return lookupCustomerDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupCustomerDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCustomerDomains(params?: LookupCustomerDomains$Params, context?: HttpContext): Observable<LookupGridResponse> {
    return this.lookupCustomerDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

}
