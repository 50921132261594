/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SyncCustomerPbxAccountRequest } from '../../models/sync-customer-pbx-account-request';
import { SyncPbxResultModel } from '../../models/sync-pbx-result-model';

export interface PbxSyncSyncCustomerPbxAccount$Params {
      body: SyncCustomerPbxAccountRequest
}

export function pbxSyncSyncCustomerPbxAccount(http: HttpClient, rootUrl: string, params: PbxSyncSyncCustomerPbxAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<SyncPbxResultModel>> {
  const rb = new RequestBuilder(rootUrl, pbxSyncSyncCustomerPbxAccount.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SyncPbxResultModel>;
    })
  );
}

pbxSyncSyncCustomerPbxAccount.PATH = '/api/pbx-sync/sync-customer-pbx-account';
