/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BasicCallLogsGridResponse } from '../../models/basic-call-logs-grid-response';
import { CallLogsFilterRequest } from '../../models/call-logs-filter-request';

export interface BasicCallLogsGetBasicCallLogsData$Params {
      body: CallLogsFilterRequest
}

export function basicCallLogsGetBasicCallLogsData(http: HttpClient, rootUrl: string, params: BasicCallLogsGetBasicCallLogsData$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicCallLogsGridResponse>> {
  const rb = new RequestBuilder(rootUrl, basicCallLogsGetBasicCallLogsData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BasicCallLogsGridResponse>;
    })
  );
}

basicCallLogsGetBasicCallLogsData.PATH = '/api/reports/basic-call-logs';
