/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { basicSoftphoneChangeExtensionPassword } from '../fn/basic-softphone/basic-softphone-change-extension-password';
import { BasicSoftphoneChangeExtensionPassword$Params } from '../fn/basic-softphone/basic-softphone-change-extension-password';
import { basicSoftphoneChangePassword } from '../fn/basic-softphone/basic-softphone-change-password';
import { BasicSoftphoneChangePassword$Params } from '../fn/basic-softphone/basic-softphone-change-password';
import { basicSoftphoneExtensionSummary } from '../fn/basic-softphone/basic-softphone-extension-summary';
import { BasicSoftphoneExtensionSummary$Params } from '../fn/basic-softphone/basic-softphone-extension-summary';
import { basicSoftphoneSaveSoftPhoneSettings } from '../fn/basic-softphone/basic-softphone-save-soft-phone-settings';
import { BasicSoftphoneSaveSoftPhoneSettings$Params } from '../fn/basic-softphone/basic-softphone-save-soft-phone-settings';
import { basicSoftphoneSendQrCode } from '../fn/basic-softphone/basic-softphone-send-qr-code';
import { BasicSoftphoneSendQrCode$Params } from '../fn/basic-softphone/basic-softphone-send-qr-code';
import { basicSoftphoneSummary } from '../fn/basic-softphone/basic-softphone-summary';
import { BasicSoftphoneSummary$Params } from '../fn/basic-softphone/basic-softphone-summary';
import { BasicSoftPhoneSummaryModel } from '../models/basic-soft-phone-summary-model';

@Injectable({ providedIn: 'root' })
export class BasicSoftphoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `basicSoftphoneSummary()` */
  static readonly BasicSoftphoneSummaryPath = '/api/basic-softphone/summary';

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSummary$Response(params?: BasicSoftphoneSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicSoftPhoneSummaryModel>> {
    return basicSoftphoneSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSummary(params?: BasicSoftphoneSummary$Params, context?: HttpContext): Observable<BasicSoftPhoneSummaryModel> {
    return this.basicSoftphoneSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSoftPhoneSummaryModel>): BasicSoftPhoneSummaryModel => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneSaveSoftPhoneSettings()` */
  static readonly BasicSoftphoneSaveSoftPhoneSettingsPath = '/api/basic-softphone/save-settings';

  /**
   * Change SoftPhone Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneSaveSoftPhoneSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneSaveSoftPhoneSettings$Response(params: BasicSoftphoneSaveSoftPhoneSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return basicSoftphoneSaveSoftPhoneSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Change SoftPhone Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneSaveSoftPhoneSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneSaveSoftPhoneSettings(params: BasicSoftphoneSaveSoftPhoneSettings$Params, context?: HttpContext): Observable<void> {
    return this.basicSoftphoneSaveSoftPhoneSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneChangePassword()` */
  static readonly BasicSoftphoneChangePasswordPath = '/api/basic-softphone/change-password';

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangePassword$Response(params: BasicSoftphoneChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return basicSoftphoneChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangePassword(params: BasicSoftphoneChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.basicSoftphoneChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneExtensionSummary()` */
  static readonly BasicSoftphoneExtensionSummaryPath = '/api/basic-softphone/extension-summary/{extension}';

  /**
   * Get extension softphone summary details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneExtensionSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneExtensionSummary$Response(params: BasicSoftphoneExtensionSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicSoftPhoneSummaryModel>> {
    return basicSoftphoneExtensionSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get extension softphone summary details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneExtensionSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneExtensionSummary(params: BasicSoftphoneExtensionSummary$Params, context?: HttpContext): Observable<BasicSoftPhoneSummaryModel> {
    return this.basicSoftphoneExtensionSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSoftPhoneSummaryModel>): BasicSoftPhoneSummaryModel => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneChangeExtensionPassword()` */
  static readonly BasicSoftphoneChangeExtensionPasswordPath = '/api/basic-softphone/change-extension-password';

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneChangeExtensionPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangeExtensionPassword$Response(params: BasicSoftphoneChangeExtensionPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return basicSoftphoneChangeExtensionPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneChangeExtensionPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangeExtensionPassword(params: BasicSoftphoneChangeExtensionPassword$Params, context?: HttpContext): Observable<void> {
    return this.basicSoftphoneChangeExtensionPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneSendQrCode()` */
  static readonly BasicSoftphoneSendQrCodePath = '/api/basic-softphone/send-qrcode/{customerUserId}';

  /**
   * Send softphone login information email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneSendQrCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSendQrCode$Response(params: BasicSoftphoneSendQrCode$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicSoftPhoneSummaryModel>> {
    return basicSoftphoneSendQrCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Send softphone login information email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneSendQrCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSendQrCode(params: BasicSoftphoneSendQrCode$Params, context?: HttpContext): Observable<BasicSoftPhoneSummaryModel> {
    return this.basicSoftphoneSendQrCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSoftPhoneSummaryModel>): BasicSoftPhoneSummaryModel => r.body)
    );
  }

}
