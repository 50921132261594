import {Injectable} from '@angular/core';
import 'twix';
import {BehaviorSubject} from 'rxjs';
import {Sort} from '@angular/material/sort';

@Injectable()
export class GridSortService {
  public gridSortSubject = new BehaviorSubject<Sort>(null);

  constructor() {
    this.gridSortSubject.subscribe(sort => {
      console.log('sort', sort);
    });
  }
}
