/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerDomainModel } from '../models/customer-domain-model';
import { CustomReportListResponse } from '../models/custom-report-list-response';
import { CustomReportModel } from '../models/custom-report-model';
import { customReportsCustomerDomains } from '../fn/custom-reports/custom-reports-customer-domains';
import { CustomReportsCustomerDomains$Params } from '../fn/custom-reports/custom-reports-customer-domains';
import { customReportsDeleteById } from '../fn/custom-reports/custom-reports-delete-by-id';
import { CustomReportsDeleteById$Params } from '../fn/custom-reports/custom-reports-delete-by-id';
import { customReportsDownloadById } from '../fn/custom-reports/custom-reports-download-by-id';
import { CustomReportsDownloadById$Params } from '../fn/custom-reports/custom-reports-download-by-id';
import { customReportsFixCustomReports } from '../fn/custom-reports/custom-reports-fix-custom-reports';
import { CustomReportsFixCustomReports$Params } from '../fn/custom-reports/custom-reports-fix-custom-reports';
import { customReportsGetById } from '../fn/custom-reports/custom-reports-get-by-id';
import { CustomReportsGetById$Params } from '../fn/custom-reports/custom-reports-get-by-id';
import { customReportsGetList } from '../fn/custom-reports/custom-reports-get-list';
import { CustomReportsGetList$Params } from '../fn/custom-reports/custom-reports-get-list';
import { customReportsPreviewById } from '../fn/custom-reports/custom-reports-preview-by-id';
import { CustomReportsPreviewById$Params } from '../fn/custom-reports/custom-reports-preview-by-id';
import { customReportsSave } from '../fn/custom-reports/custom-reports-save';
import { CustomReportsSave$Params } from '../fn/custom-reports/custom-reports-save';
import { customReportsTriggerScheduler } from '../fn/custom-reports/custom-reports-trigger-scheduler';
import { CustomReportsTriggerScheduler$Params } from '../fn/custom-reports/custom-reports-trigger-scheduler';
import { customReportsUnsubscribe } from '../fn/custom-reports/custom-reports-unsubscribe';
import { CustomReportsUnsubscribe$Params } from '../fn/custom-reports/custom-reports-unsubscribe';

@Injectable({ providedIn: 'root' })
export class CustomReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `customReportsGetList()` */
  static readonly CustomReportsGetListPath = '/api/custom-reports/list';

  /**
   * Get list of custom reports.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsGetList$Response(params: CustomReportsGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomReportListResponse>> {
    return customReportsGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of custom reports.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsGetList(params: CustomReportsGetList$Params, context?: HttpContext): Observable<CustomReportListResponse> {
    return this.customReportsGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportListResponse>): CustomReportListResponse => r.body)
    );
  }

  /** Path part for operation `customReportsSave()` */
  static readonly CustomReportsSavePath = '/api/custom-reports/report';

  /**
   * Save custom report details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsSave$Response(params: CustomReportsSave$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomReportModel>> {
    return customReportsSave(this.http, this.rootUrl, params, context);
  }

  /**
   * Save custom report details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsSave(params: CustomReportsSave$Params, context?: HttpContext): Observable<CustomReportModel> {
    return this.customReportsSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportModel>): CustomReportModel => r.body)
    );
  }

  /** Path part for operation `customReportsGetById()` */
  static readonly CustomReportsGetByIdPath = '/api/custom-reports/report/{id}';

  /**
   * Get custom report details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsGetById$Response(params: CustomReportsGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomReportModel>> {
    return customReportsGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get custom report details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsGetById(params: CustomReportsGetById$Params, context?: HttpContext): Observable<CustomReportModel> {
    return this.customReportsGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportModel>): CustomReportModel => r.body)
    );
  }

  /** Path part for operation `customReportsDeleteById()` */
  static readonly CustomReportsDeleteByIdPath = '/api/custom-reports/report/{id}';

  /**
   * Delete custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDeleteById$Response(params: CustomReportsDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return customReportsDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDeleteById(params: CustomReportsDeleteById$Params, context?: HttpContext): Observable<boolean> {
    return this.customReportsDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `customReportsDownloadById()` */
  static readonly CustomReportsDownloadByIdPath = '/api/custom-reports/download/{id}';

  /**
   * Download custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsDownloadById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDownloadById$Response(params: CustomReportsDownloadById$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return customReportsDownloadById(this.http, this.rootUrl, params, context);
  }

  /**
   * Download custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsDownloadById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDownloadById(params: CustomReportsDownloadById$Params, context?: HttpContext): Observable<string> {
    return this.customReportsDownloadById$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsPreviewById()` */
  static readonly CustomReportsPreviewByIdPath = '/api/custom-reports/preview/{id}';

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsPreviewById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsPreviewById$Response(params: CustomReportsPreviewById$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return customReportsPreviewById(this.http, this.rootUrl, params, context);
  }

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsPreviewById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsPreviewById(params: CustomReportsPreviewById$Params, context?: HttpContext): Observable<string> {
    return this.customReportsPreviewById$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsUnsubscribe()` */
  static readonly CustomReportsUnsubscribePath = '/api/custom-reports/unsubscribe';

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsUnsubscribe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsUnsubscribe$Response(params: CustomReportsUnsubscribe$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return customReportsUnsubscribe(this.http, this.rootUrl, params, context);
  }

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsUnsubscribe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsUnsubscribe(params: CustomReportsUnsubscribe$Params, context?: HttpContext): Observable<string> {
    return this.customReportsUnsubscribe$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsCustomerDomains()` */
  static readonly CustomReportsCustomerDomainsPath = '/api/custom-reports/lookup-domains';

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsCustomerDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsCustomerDomains$Response(params?: CustomReportsCustomerDomains$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerDomainModel>>> {
    return customReportsCustomerDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsCustomerDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsCustomerDomains(params?: CustomReportsCustomerDomains$Params, context?: HttpContext): Observable<Array<CustomerDomainModel>> {
    return this.customReportsCustomerDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerDomainModel>>): Array<CustomerDomainModel> => r.body)
    );
  }

  /** Path part for operation `customReportsTriggerScheduler()` */
  static readonly CustomReportsTriggerSchedulerPath = '/api/custom-reports/trigger-scheduler';

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsTriggerScheduler()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsTriggerScheduler$Response(params?: CustomReportsTriggerScheduler$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return customReportsTriggerScheduler(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsTriggerScheduler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsTriggerScheduler(params?: CustomReportsTriggerScheduler$Params, context?: HttpContext): Observable<string> {
    return this.customReportsTriggerScheduler$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsFixCustomReports()` */
  static readonly CustomReportsFixCustomReportsPath = '/api/custom-reports/update-custom-reports';

  /**
   * Update custom reports with additional data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsFixCustomReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsFixCustomReports$Response(params?: CustomReportsFixCustomReports$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomReportModel>> {
    return customReportsFixCustomReports(this.http, this.rootUrl, params, context);
  }

  /**
   * Update custom reports with additional data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsFixCustomReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsFixCustomReports(params?: CustomReportsFixCustomReports$Params, context?: HttpContext): Observable<CustomReportModel> {
    return this.customReportsFixCustomReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportModel>): CustomReportModel => r.body)
    );
  }

}
