import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom, Observable, timer } from 'rxjs';
import { VersionCheckerDialogComponent } from '../../dialogs/version-checker-dialog/version-checker-dialog.component';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckerService {
  private _url: string;
  private _currentVersion: string;
  private _updateAvailable = false;
  private _isDialogOpen = false;
  private readonly _everyFiveMinutes$ = timer(0, 5 * 60 * 1000);

  constructor(private readonly _dialog: MatDialog, private http: HttpClient) {
  }

  private getVersion(): Observable<string> {
    return this.http.get<{ version: string }>(`${this._url}?t=${new Date().getTime()}`).pipe(
      map(response => response.version)
    );
  }

  public initCheckForUpdates(url: string): void {
    this._url = url;
    // Get the initial version
    this.getVersion().subscribe(version => {
      console.log('Current Version:', version);
      this._currentVersion = version;
    });

    this.unregisterServiceWorker();

    console.log('Init Check For Updates');
    this._everyFiveMinutes$.subscribe(async () => {
      try {
        const isUpdate = this._updateAvailable || (await this.checkForUpdate());
        console.log(`checking for updates: is update available: ${isUpdate}`);
        if (isUpdate) {
          this._updateAvailable = true;
          if (!this._isDialogOpen) {
            this.openVersionCheckerDialog();
          }
        }
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }

  private openVersionCheckerDialog(): void {
    this._isDialogOpen = true;
    const dialogRef = this._dialog.open(VersionCheckerDialogComponent, {
      width: '500px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => {
      this._isDialogOpen = false;
    });
  }

  private async checkForUpdate() {
    const newVersion = await lastValueFrom(this.getVersion());
    return this._currentVersion && this._currentVersion !== newVersion
  }

  private unregisterServiceWorker() {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
  }
}
