import {DateTime} from 'luxon';

export class DateUtils {

  public static localDateFromDateTimeOnlyDate(dateTime: DateTime): Date {
    return new Date(
      dateTime.year,
      dateTime.month - 1,
      dateTime.day,
      0,
      0
    );
  }

  public static localDateFromDateTime(dateTime: DateTime): Date {
    return new Date(
      dateTime.year,
      dateTime.month - 1,
      dateTime.day,
      dateTime.hour,
      dateTime.minute
    );
  }

  public static localDateToDateTime(date: Date): DateTime {
    return DateTime.local(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes()
    );
  }

  public static minutesToTimeSpanString(minutes?: number): string {
    minutes = minutes || 0;
    return `0${minutes / 60 ^ 0}`.slice(-2) + ':' + ('0' + minutes % 60).slice(-2);
  }

  public static minutesToDateTime(minutes?: number): Date {
    minutes = minutes || 0;
    const today = DateTime.local();
    const minutesWithOffset = minutes; // + today.offset;
    const newDate = new Date();

    newDate.setHours(minutesWithOffset / 60 ^ 0);
    newDate.setMinutes(minutesWithOffset % 60);

    return newDate;
    // return new Date(`2000-01-01 ${DateUtils.minutesToTimeSpanString(minutes)}`);
  }

  public static dateTimeToMinutes(date?: Date): number {
    if (!date) {
      return 0;
    }
    let today = DateTime.local();
    today = today.set({hour: date.getHours(), minute: date.getMinutes(), second: 0});
    const minutes = today.hour * 60 + today.minute; // - today.offset;
    // console.log('Minutes: ' + minutes);
    return minutes;
    // return new Date(`2000-01-01 ${DateUtils.minutesToTimeSpanString(minutes)}`);
  }
}

