import {Injectable, Injector} from '@angular/core';
import {AuthService as ApiAuthService} from '../../api/services/auth.service';
import {CurrentUser, CurrentUserAccountModel, CustomerUserUiSettingsModel, LocalLogin} from '../../api/models';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {ResetPasswordModel} from '../../api/models/reset-password-model';
import {RolesEnum} from './roles.enum';
import {MenuGroupEnum} from './menu-group.enum';
import {UsersService} from '../../api/services/users.service';
import {GoogleAnalyticsService} from '../../analytics/google-analytics.service';
import {ModulesEnum} from './modules.enum';
import {Settings} from 'luxon';
import {EventsService} from '../../events/events.service';
import {HeaderService} from '../services/header.service';

@Injectable()
export class AuthService {

  static currentUserKey = `${AuthService.name}_currentUser`;
  // store the URL so we can redirect after logging in
  redirectUrl = '/';

  currentUser = new BehaviorSubject<CurrentUser | undefined>(undefined);
  currentMenuGroup = new BehaviorSubject<MenuGroupEnum | undefined>(undefined);

  public permissions = {
    hasSmsAccess: false,
    hasSmsViewAccess: false,
    hasSmsEditAccess: false,

    hasFaxAccess: false,
    hasFaxViewAccess: false,
    hasFaxEditAccess: false,

    hasPhoneNumberAccess: false,
    hasPhoneNumberViewAccess: false,
    hasPhoneNumberEditAccess: false,

    hasMessagingAccess: false,
    hasMessagingViewAccess: false,
    hasMessagingEditAccess: false,
    hasMessagingDeleteAccess: false,

    hasProMessagingAccess: false,
    hasProMessagingViewAccess: false,
    hasProMessagingEditAccess: false,
    hasProMessagingDeleteAccess: false,
  };

  public isLoggedIn = false;
  private aliveTime = 0;

  constructor(private apiAuthService: ApiAuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private usersService: UsersService,
              private eventsService: EventsService,
              private injector: Injector,
              /*private googleAnalytics: GoogleAnalyticsService*/) {
    console.log('AuthService created');

    this.currentUser.subscribe((value) => {
      this.isLoggedIn = !!value && !value.twoFactorNextStepRequested;
    });

    this.initCurrentUser();
  }

  async alive(force?: boolean): Promise<boolean> {
    const currentTime = new Date().getTime();

    if (currentTime - 10000 > this.aliveTime || force) {
      this.aliveTime = currentTime;
      try {
        const response = await this.usersService.usersAlive().toPromise();
        this.saveCurrentUser(response);
        return true;
      } catch (e) {
        return false;
      }
    }

    return false;
  }

  async redirectAfterLogin(): Promise<boolean> {
    const headerService = this.injector.get(HeaderService);
    this.redirectUrl = decodeURIComponent(this.redirectUrl);

    console.log('redirectAfterLogin', this.redirectUrl);

    if (this.redirectUrl) {
      // return await this.router.navigateByUrl(this.redirectUrl);
      location.replace(`/#${this.redirectUrl}`);
      return true;
    }

    if (headerService.themePrefix) {
      this.redirectUrl = headerService.themePrefix + '/home';
    }
    location.replace(`/#${this.redirectUrl}`);
    return true;
    // return await this.router.navigateByUrl(this.redirectUrl);
  }

  async login(model: LocalLogin): Promise<boolean> {

    try {
      const authResponse = await this.apiAuthService.authenticationLogin({body: model}).toPromise();

      this.saveCurrentUser(authResponse);

      return true;
    } catch (e) {
      console.log(e);

      this.saveCurrentUser(undefined);

      return false;
    }
  }

  async verifyToken(token: string): Promise<boolean> {
    try {
      const authResponse = await this.apiAuthService.authenticationVerifyOtp({body: {token: token}}).toPromise();

      this.saveCurrentUser(authResponse);

      return true;
    } catch (e) {
      console.log(e);

      this.saveCurrentUser(undefined);

      return false;
    }
  }

  async logout(clean = false): Promise<void> {
    try {
      await this.apiAuthService.authenticationLogout().toPromise();
      if (clean) {
        this.redirectUrl = '/';
        console.log('this.redirectUrl:' + this.redirectUrl);
      }
    } catch (e) {
      console.log(e);
    }

    const retUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'];
    const navigateResult = await this.router.navigate(['auth/login'], {queryParams: {redirectUrl: retUrl || this.redirectUrl}});
    console.log('logout navigate result: ' + navigateResult, retUrl, this.redirectUrl);
    this.saveCurrentUser(undefined);
  }

  async resendOtp(): Promise<string> {
    try {
      return await this.apiAuthService.authenticationResendOtp().toPromise();
    } catch (e) {
      console.log(e);
      return 'Failed to resend one time password';
    }
  }

  async resetPassword(email): Promise<boolean> {
    try {
      await this.apiAuthService.authenticationResetPassword({body: email}).toPromise();
      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

  async submitResetPassword(model: ResetPasswordModel): Promise<boolean> {
    try {
      await this.apiAuthService.authenticationResetPasswordConfirm({body: model}).toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async switchCompany(company: CurrentUserAccountModel): Promise<boolean> {
    try {
      await this.apiAuthService.authenticationSwitchCompany({body: company}).toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async getTwoFactorVerify(provider: string, token: string, phoneNumber: string): Promise<boolean> {
    const response = await this.usersService.usersTwoFactorVerify({
      provider: provider,
      token: token,
      phoneNumber: phoneNumber
    }).toPromise();

    return response.success;
  }

  userHasPackage(packageName: ModulesEnum | ModulesEnum[]): boolean {
    const user = this.currentUser.getValue();

    if (!user) {
      return false;
    }

    let packages: ModulesEnum[];

    if (!Array.isArray(packageName)) {
      packages = [packageName];
    } else {
      packages = packageName;
    }

    let hasPackagePermission = false;

    for (const userPackageName of packages) {
      const userPackage = user.modules.find((item) => {
        return item === userPackageName;
      });

      if (!!userPackage) {
        hasPackagePermission = true;
        break;
      }
    }

    return hasPackagePermission;
  }

  userHasPermission(permissions: RolesEnum[]) {
    if (permissions.length <= 0) {
      return true;
    }

    const user = this.currentUser.getValue();

    if (!user) {
      return false;
    }

    if (permissions.includes(RolesEnum.Any)) {
      return true;
    }

    const userPermissions = permissions.filter(e => user.roles.includes(e as any));

    return userPermissions && userPermissions.length > 0;
  }

  hasAccess(roles: RolesEnum[]) {
    if (!roles || roles.length <= 0) {
      return false;
    }

    const user = this.currentUser.getValue();

    if (!user) {
      return false;
    }

    const userPermissions = roles.filter(e => user.roles.includes(e as any));

    return userPermissions && userPermissions.length > 0;
  }

  initCurrentUser(): void {
    const item = localStorage.getItem(AuthService.currentUserKey);
    if (item) {
      this.currentUser.next(JSON.parse(item));
    } else {
      this.currentUser.next(undefined);
    }
  }

  saveCurrentUser(user?: CurrentUser) {
    if (user) {
      if (!user.modules.includes('DownloadsController')) {
        user.modules.push('DownloadsController');
      }
      const item = JSON.stringify(user);
      localStorage.setItem(AuthService.currentUserKey, item);
      //this.googleAnalytics.setUserId(user.username);
      Settings.defaultZone = user.settings?.timezone || 'America/New_York';
      if (!user.twoFactorNextStepRequested) {
        this.eventsService.connect();
      }
    } else {
      localStorage.removeItem(AuthService.currentUserKey);
      //this.googleAnalytics.setUserId(undefined);
      this.eventsService.disconnect();
    }

    this.updateUserPermissions();

    this.currentUser.next(user);
  }

  async saveUiSettings(settings: Partial<CustomerUserUiSettingsModel>) {
    try {
      const currentUser = this.currentUser.getValue();
      if (currentUser) {
        const settingsToSave = Object.assign(currentUser.uiSettings, settings);
        await this.usersService.usersSaveUiSettings({body: settingsToSave}).toPromise();

        currentUser.uiSettings = settingsToSave;
        this.saveCurrentUser(currentUser);
      }
    } catch (e) {
      console.log(e);
    }
  }

  updateUserPermissions() {
    this.permissions.hasSmsAccess = this.userHasPackage([ModulesEnum.MessagingModule, ModulesEnum.ProMessagingModule]);
    this.permissions.hasSmsViewAccess = this.permissions.hasSmsAccess
      && (this.hasAccess([RolesEnum.Messaging_View, RolesEnum.Messaging_Edit, RolesEnum.Messaging_Delete])
        || this.hasAccess([RolesEnum.ProMessaging_View, RolesEnum.ProMessaging_Edit, RolesEnum.ProMessaging_Delete]));
    this.permissions.hasSmsEditAccess = this.permissions.hasSmsAccess
      && (this.hasAccess([RolesEnum.Messaging_Edit, RolesEnum.Messaging_Delete])
        || this.hasAccess([RolesEnum.ProMessaging_Edit, RolesEnum.ProMessaging_Delete]));

    this.permissions.hasFaxAccess = this.userHasPackage(ModulesEnum.FaxModule);
    this.permissions.hasFaxViewAccess = this.permissions.hasFaxAccess
      && this.hasAccess([RolesEnum.Fax_View, RolesEnum.Fax_Edit, RolesEnum.Fax_Delete]);
    this.permissions.hasFaxEditAccess = this.permissions.hasFaxAccess
      && this.hasAccess([RolesEnum.Fax_Edit, RolesEnum.Fax_Delete]);

    this.permissions.hasPhoneNumberAccess = this.userHasPackage(ModulesEnum.PhoneNumbersModule);
    this.permissions.hasPhoneNumberViewAccess = this.permissions.hasPhoneNumberAccess
      && this.hasAccess([RolesEnum.PhoneNumbers_View, RolesEnum.PhoneNumbers_Edit, RolesEnum.PhoneNumbers_Delete]);
    this.permissions.hasPhoneNumberEditAccess = this.permissions.hasPhoneNumberAccess
      && this.hasAccess([RolesEnum.PhoneNumbers_Edit, RolesEnum.PhoneNumbers_Delete]);

    this.permissions.hasMessagingAccess = this.userHasPackage(ModulesEnum.MessagingModule);
    this.permissions.hasMessagingViewAccess = this.permissions.hasMessagingAccess
      && this.hasAccess([RolesEnum.Messaging_View, RolesEnum.Messaging_Edit, RolesEnum.Messaging_Delete]);
    this.permissions.hasMessagingEditAccess = this.permissions.hasMessagingAccess
      && this.hasAccess([RolesEnum.Messaging_Edit, RolesEnum.Messaging_Delete]);
    this.permissions.hasMessagingDeleteAccess = this.permissions.hasMessagingAccess
      && this.hasAccess([RolesEnum.Messaging_Delete]);

    this.permissions.hasProMessagingAccess = this.userHasPackage(ModulesEnum.ProMessagingModule);
    this.permissions.hasProMessagingViewAccess = this.permissions.hasProMessagingAccess
      && this.hasAccess([RolesEnum.ProMessaging_View, RolesEnum.ProMessaging_Edit, RolesEnum.ProMessaging_Delete]);
    this.permissions.hasProMessagingEditAccess = this.permissions.hasProMessagingAccess
      && this.hasAccess([RolesEnum.ProMessaging_Edit, RolesEnum.ProMessaging_Delete]);
    this.permissions.hasProMessagingDeleteAccess = this.permissions.hasProMessagingAccess
      && this.hasAccess([RolesEnum.ProMessaging_Delete]);
  }
}
