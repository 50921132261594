/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AcrobitsContactsResponse } from '../models/acrobits-contacts-response';
import { publicBasicSoftphoneContacts } from '../fn/public-basic-softphone/public-basic-softphone-contacts';
import { PublicBasicSoftphoneContacts$Params } from '../fn/public-basic-softphone/public-basic-softphone-contacts';
import { publicBasicSoftphoneRegisterDevice } from '../fn/public-basic-softphone/public-basic-softphone-register-device';
import { PublicBasicSoftphoneRegisterDevice$Params } from '../fn/public-basic-softphone/public-basic-softphone-register-device';
import { publicBasicSoftphoneSoftPhoneGetMessages } from '../fn/public-basic-softphone/public-basic-softphone-soft-phone-get-messages';
import { PublicBasicSoftphoneSoftPhoneGetMessages$Params } from '../fn/public-basic-softphone/public-basic-softphone-soft-phone-get-messages';
import { publicBasicSoftphoneSoftPhoneSendMessage } from '../fn/public-basic-softphone/public-basic-softphone-soft-phone-send-message';
import { PublicBasicSoftphoneSoftPhoneSendMessage$Params } from '../fn/public-basic-softphone/public-basic-softphone-soft-phone-send-message';
import { publicBasicSoftphoneUnregisterDevice } from '../fn/public-basic-softphone/public-basic-softphone-unregister-device';
import { PublicBasicSoftphoneUnregisterDevice$Params } from '../fn/public-basic-softphone/public-basic-softphone-unregister-device';

@Injectable({ providedIn: 'root' })
export class PublicBasicSoftphoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `publicBasicSoftphoneSoftPhoneGetMessages()` */
  static readonly PublicBasicSoftphoneSoftPhoneGetMessagesPath = '/api/public-basic-softphone/soft-phone-get-messages';

  /**
   * Get messages for softphone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneSoftPhoneGetMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneSoftPhoneGetMessages$Response(params: PublicBasicSoftphoneSoftPhoneGetMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicBasicSoftphoneSoftPhoneGetMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get messages for softphone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneSoftPhoneGetMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneSoftPhoneGetMessages(params: PublicBasicSoftphoneSoftPhoneGetMessages$Params, context?: HttpContext): Observable<{
}> {
    return this.publicBasicSoftphoneSoftPhoneGetMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneSoftPhoneSendMessage()` */
  static readonly PublicBasicSoftphoneSoftPhoneSendMessagePath = '/api/public-basic-softphone/soft-phone-send-message';

  /**
   * Send message from softphone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneSoftPhoneSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneSoftPhoneSendMessage$Response(params: PublicBasicSoftphoneSoftPhoneSendMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicBasicSoftphoneSoftPhoneSendMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Send message from softphone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneSoftPhoneSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneSoftPhoneSendMessage(params: PublicBasicSoftphoneSoftPhoneSendMessage$Params, context?: HttpContext): Observable<{
}> {
    return this.publicBasicSoftphoneSoftPhoneSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneRegisterDevice()` */
  static readonly PublicBasicSoftphoneRegisterDevicePath = '/api/public-basic-softphone/register-device';

  /**
   * Register basic softphone device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneRegisterDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneRegisterDevice$Response(params: PublicBasicSoftphoneRegisterDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicBasicSoftphoneRegisterDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Register basic softphone device.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneRegisterDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneRegisterDevice(params: PublicBasicSoftphoneRegisterDevice$Params, context?: HttpContext): Observable<{
}> {
    return this.publicBasicSoftphoneRegisterDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneUnregisterDevice()` */
  static readonly PublicBasicSoftphoneUnregisterDevicePath = '/api/public-basic-softphone/unregister-device';

  /**
   * Unregister basic softphone device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneUnregisterDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneUnregisterDevice$Response(params: PublicBasicSoftphoneUnregisterDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return publicBasicSoftphoneUnregisterDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Unregister basic softphone device.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneUnregisterDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneUnregisterDevice(params: PublicBasicSoftphoneUnregisterDevice$Params, context?: HttpContext): Observable<{
}> {
    return this.publicBasicSoftphoneUnregisterDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneContacts()` */
  static readonly PublicBasicSoftphoneContactsPath = '/api/public-basic-softphone/contacts';

  /**
   * Returns customer contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneContacts$Response(params: PublicBasicSoftphoneContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AcrobitsContactsResponse>>> {
    return publicBasicSoftphoneContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns customer contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneContacts(params: PublicBasicSoftphoneContacts$Params, context?: HttpContext): Observable<Array<AcrobitsContactsResponse>> {
    return this.publicBasicSoftphoneContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AcrobitsContactsResponse>>): Array<AcrobitsContactsResponse> => r.body)
    );
  }

}
