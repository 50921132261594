/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanResponse } from '../models/boolean-response';
import { UserDeviceModel } from '../models/user-device-model';
import { userDevicesDeleteDevice } from '../fn/user-devices/user-devices-delete-device';
import { UserDevicesDeleteDevice$Params } from '../fn/user-devices/user-devices-delete-device';
import { userDevicesGetDevices } from '../fn/user-devices/user-devices-get-devices';
import { UserDevicesGetDevices$Params } from '../fn/user-devices/user-devices-get-devices';
import { userDevicesSaveDevice } from '../fn/user-devices/user-devices-save-device';
import { UserDevicesSaveDevice$Params } from '../fn/user-devices/user-devices-save-device';

@Injectable({ providedIn: 'root' })
export class UserDevicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userDevicesGetDevices()` */
  static readonly UserDevicesGetDevicesPath = '/api/user-devices/list';

  /**
   * Get current user devices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesGetDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesGetDevices$Response(params?: UserDevicesGetDevices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDeviceModel>>> {
    return userDevicesGetDevices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current user devices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesGetDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesGetDevices(params?: UserDevicesGetDevices$Params, context?: HttpContext): Observable<Array<UserDeviceModel>> {
    return this.userDevicesGetDevices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDeviceModel>>): Array<UserDeviceModel> => r.body)
    );
  }

  /** Path part for operation `userDevicesSaveDevice()` */
  static readonly UserDevicesSaveDevicePath = '/api/user-devices/save';

  /**
   * Save current user device information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesSaveDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDevicesSaveDevice$Response(params: UserDevicesSaveDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return userDevicesSaveDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Save current user device information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesSaveDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDevicesSaveDevice(params: UserDevicesSaveDevice$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.userDevicesSaveDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `userDevicesDeleteDevice()` */
  static readonly UserDevicesDeleteDevicePath = '/api/user-devices/delete/{id}';

  /**
   * Delete User Device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesDeleteDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesDeleteDevice$Response(params: UserDevicesDeleteDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return userDevicesDeleteDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete User Device.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesDeleteDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesDeleteDevice(params: UserDevicesDeleteDevice$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.userDevicesDeleteDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

}
