/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BooleanResponse } from '../../models/boolean-response';

export interface UsersTwoFactorVerify$Params {
  provider: string;
  token: string;
  phoneNumber: string;
}

export function usersTwoFactorVerify(http: HttpClient, rootUrl: string, params: UsersTwoFactorVerify$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
  const rb = new RequestBuilder(rootUrl, usersTwoFactorVerify.PATH, 'get');
  if (params) {
    rb.path('provider', params.provider, {});
    rb.query('token', params.token, {});
    rb.query('phoneNumber', params.phoneNumber, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BooleanResponse>;
    })
  );
}

usersTwoFactorVerify.PATH = '/api/users/two-factor-verify/{provider}';
