import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class NotificationService {

  defaultDuration = 5000;
  notifications: NotificationModel[] = [];

  notifySuccess(message: string) {
    this.notify(NotificationType.success, message);
  }

  notifyDanger(message: string) {
    this.notify(NotificationType.danger, message);
  }

  notify(type: NotificationType, message: string) {
    console.log('show notification', type, message);
    const notification: NotificationModel = {
      id: _.uniqueId('notification_'),
      message: message,
      type: type,
      allowClose: true,
      duration: this.defaultDuration
    };

    if (notification.duration) {
      setTimeout(() => {
        this.notifications.shift();
      }, notification.duration);
    }

    this.notifications.push(notification);
  }

  remove(notification: NotificationModel) {
    console.log('remove notification');
    const indexToRemove = this.notifications.findIndex((item) => {
      return item.id === notification.id;
    });

    if (indexToRemove > -1) {
      this.notifications.splice(indexToRemove, 1);
    }
  }

  clearAllNotifications() {
    console.log('clearAllNotifications');
    const count = this.notifications.length;
    this.notifications.splice(0, count - 1);
  }
}

export interface NotificationModel {
  id: string;
  message: string;
  type: NotificationType;
  allowClose?: boolean;
  duration?: number;
}

export enum NotificationType {
  info = 'alert-info',
  success = 'alert-success',
  warning = 'alert-warning',
  danger = 'alert-danger'
}
