import {EventEmitter, Injectable, Injector} from '@angular/core';
import {io, Socket} from 'socket.io-client';
import {AuthService} from '../core/security/auth.service';
import {NotificationModel} from '../api/models/notification-model';

@Injectable()
export class EventsService {
  private socket: Socket;
  private forceDisconnect = false;
  private shouldBeConnected = false;
  private reconnectInterval: any;

  public connectEmitter = new EventEmitter();

  public faxInEmitter = new EventEmitter<any>();
  public faxOutEmitter = new EventEmitter<any>();
  public notificationEmitter = new EventEmitter<NotificationModel>();

  constructor(private readonly injector: Injector) {
    this.initEvents();
  }

  isConnected() {
    return this.socket.connected;
  }

  sendMessage(message) {
    console.log('socket.io', 'sendMessage');
    this.socket.emit('event', message);
  }

  initEvents() {
    console.log('socket.io', 'init');
    this.socket = io({transports: ['websocket'], autoConnect: false, reconnection: false});

    this.socket.on('connect', () => {
      console.log('socket.io', 'Connected to socket.io');
      this.connectEmitter.emit()
    });

    this.socket.on('connect_error', (data) => {
      console.log('socket.io', 'Socket connect_error', data);
      if (data && data.message) {
        if (data.message.indexOf('AuthorizeFail') > -1) {
          // do log out
          const authService = this.injector.get(AuthService);
          const currentUser = authService.currentUser.getValue();
          if (currentUser && !currentUser.twoFactorNextStepRequested) {
            authService.logout().then();
          }
          //return;
        }
      }

      this.reconnect();
    });

    this.socket.on('connect_timeout', (data) => {
      console.log('socket.io', 'Socket connect_timeout', data);
      this.reconnect();
    });

    this.socket.on('error', (data) => {
      console.log('socket.io', 'Socket Error', data);
      this.reconnect();
    });

    this.socket.on('reconnect_error', (data) => {
      console.log('socket.io', 'Socket reconnect_error', data);
      this.reconnect();
    });

    this.socket.on('reconnect_attempt', (data) => {
      console.log('socket.io', 'Socket reconnect_attempt', data);
      this.reconnect();
    });

    this.socket.on('disconnect', () => {
      console.log('socket.io', 'Disconnected from socket.io');
      this.reconnect();
    });

    this.subscribeEvents('reconnect-rooms', (event, data: any) => {
      this.disconnect();
    });

    this.subscribeEvents('fax-out', (event, data: any) => {
      this.faxOutEmitter.emit(data)
    });

    this.subscribeEvents('fax-in', (event, data: any) => {
      this.faxInEmitter.emit(data);
    });

    this.subscribeEvents('notification', (event, data: any) => {
      this.notificationEmitter.emit(data)
    });

  }

  subscribeEvents(event, cb?: Function) {
    console.log('socket.io', 'subscribeEvents', event);
    if (this.socket) {
      this.socket.on(event, (data) => {
        if (cb) {
          cb(event, data);
        } else {
          console.log('ws: ', event, data);
        }
      });
    }
  }

  startReconnectCheck() {
    setTimeout(() => {
      console.log('socket.io', 'check connection: ' + this.socket.connected);
      if (this.shouldBeConnected && !this.socket.connected) {
        this.connect();
      }
    }, 1000);
  }

  reconnect() {
    console.log('socket.io', 'reconnect');
    if (!this.socket.connected && !this.forceDisconnect) {
      this.startReconnectCheck();
    }
  }

  connect() {
    console.log('socket.io', 'connect');
    this.shouldBeConnected = true;
    this.forceDisconnect = false;
    if (!this.socket.connected) {
      console.log('socket.io', 'not connected - try to connect');
      this.socket.connect();
    }
  }

  disconnect() {
    console.log('socket.io', 'disconnect');

    this.shouldBeConnected = false;
    this.forceDisconnect = true;
    this.socket.disconnect();
  }
}
