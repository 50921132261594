/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activityDashboardGetActivityDashboardData } from '../fn/reports/activity-dashboard-get-activity-dashboard-data';
import { ActivityDashboardGetActivityDashboardData$Params } from '../fn/reports/activity-dashboard-get-activity-dashboard-data';
import { ActivityTotalModel } from '../models/activity-total-model';
import { basicCallLogsGetBasicCallLogsData } from '../fn/reports/basic-call-logs-get-basic-call-logs-data';
import { BasicCallLogsGetBasicCallLogsData$Params } from '../fn/reports/basic-call-logs-get-basic-call-logs-data';
import { basicCallLogsGetExport } from '../fn/reports/basic-call-logs-get-export';
import { BasicCallLogsGetExport$Params } from '../fn/reports/basic-call-logs-get-export';
import { BasicCallLogsGridResponse } from '../models/basic-call-logs-grid-response';
import { callLogsFixCdrRecords } from '../fn/reports/call-logs-fix-cdr-records';
import { CallLogsFixCdrRecords$Params } from '../fn/reports/call-logs-fix-cdr-records';
import { callLogsGetCallLogsData } from '../fn/reports/call-logs-get-call-logs-data';
import { CallLogsGetCallLogsData$Params } from '../fn/reports/call-logs-get-call-logs-data';
import { callLogsGetExport } from '../fn/reports/call-logs-get-export';
import { CallLogsGetExport$Params } from '../fn/reports/call-logs-get-export';
import { CallLogsGridResponse } from '../models/call-logs-grid-response';
import { callLogsRecoverMergedRecords } from '../fn/reports/call-logs-recover-merged-records';
import { CallLogsRecoverMergedRecords$Params } from '../fn/reports/call-logs-recover-merged-records';
import { GroupsAgentSummaryGridResponse } from '../models/groups-agent-summary-grid-response';
import { groupsDashboardGetGroupsDashboardData } from '../fn/reports/groups-dashboard-get-groups-dashboard-data';
import { GroupsDashboardGetGroupsDashboardData$Params } from '../fn/reports/groups-dashboard-get-groups-dashboard-data';
import { groupsSummaryGetAgentsSummaryData } from '../fn/reports/groups-summary-get-agents-summary-data';
import { GroupsSummaryGetAgentsSummaryData$Params } from '../fn/reports/groups-summary-get-agents-summary-data';
import { groupsSummaryGetExport } from '../fn/reports/groups-summary-get-export';
import { GroupsSummaryGetExport$Params } from '../fn/reports/groups-summary-get-export';
import { groupsSummaryGetExportAgents } from '../fn/reports/groups-summary-get-export-agents';
import { GroupsSummaryGetExportAgents$Params } from '../fn/reports/groups-summary-get-export-agents';
import { groupsSummaryGetSummaryData } from '../fn/reports/groups-summary-get-summary-data';
import { GroupsSummaryGetSummaryData$Params } from '../fn/reports/groups-summary-get-summary-data';
import { GroupsSummaryResponse } from '../models/groups-summary-response';
import { GroupsTotalModel } from '../models/groups-total-model';
import { huntGroupsDashboardGetGroupsDashboardData } from '../fn/reports/hunt-groups-dashboard-get-groups-dashboard-data';
import { HuntGroupsDashboardGetGroupsDashboardData$Params } from '../fn/reports/hunt-groups-dashboard-get-groups-dashboard-data';
import { PbxAccountLookupModel } from '../models/pbx-account-lookup-model';
import { RawCallModel } from '../models/raw-call-model';
import { reportsGetCallDetails } from '../fn/reports/reports-get-call-details';
import { ReportsGetCallDetails$Params } from '../fn/reports/reports-get-call-details';
import { reportsGetPbxAccountData } from '../fn/reports/reports-get-pbx-account-data';
import { ReportsGetPbxAccountData$Params } from '../fn/reports/reports-get-pbx-account-data';
import { usersSummaryGetExport } from '../fn/reports/users-summary-get-export';
import { UsersSummaryGetExport$Params } from '../fn/reports/users-summary-get-export';
import { usersSummaryGetUsersDashboardData } from '../fn/reports/users-summary-get-users-dashboard-data';
import { UsersSummaryGetUsersDashboardData$Params } from '../fn/reports/users-summary-get-users-dashboard-data';
import { usersSummaryGetUsersSummaryData } from '../fn/reports/users-summary-get-users-summary-data';
import { UsersSummaryGetUsersSummaryData$Params } from '../fn/reports/users-summary-get-users-summary-data';
import { UsersSummaryGridResponse } from '../models/users-summary-grid-response';
import { UsersTotalModel } from '../models/users-total-model';

@Injectable({ providedIn: 'root' })
export class ReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityDashboardGetActivityDashboardData()` */
  static readonly ActivityDashboardGetActivityDashboardDataPath = '/api/reports/activity-dashboard-data';

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardGetActivityDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardGetActivityDashboardData$Response(params: ActivityDashboardGetActivityDashboardData$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityTotalModel>> {
    return activityDashboardGetActivityDashboardData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardGetActivityDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardGetActivityDashboardData(params: ActivityDashboardGetActivityDashboardData$Params, context?: HttpContext): Observable<ActivityTotalModel> {
    return this.activityDashboardGetActivityDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityTotalModel>): ActivityTotalModel => r.body)
    );
  }

  /** Path part for operation `groupsDashboardGetGroupsDashboardData()` */
  static readonly GroupsDashboardGetGroupsDashboardDataPath = '/api/reports/groups-dashboard-data';

  /**
   * Get groups dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsDashboardGetGroupsDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsDashboardGetGroupsDashboardData$Response(params: GroupsDashboardGetGroupsDashboardData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupsTotalModel>> {
    return groupsDashboardGetGroupsDashboardData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsDashboardGetGroupsDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsDashboardGetGroupsDashboardData(params: GroupsDashboardGetGroupsDashboardData$Params, context?: HttpContext): Observable<GroupsTotalModel> {
    return this.groupsDashboardGetGroupsDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsTotalModel>): GroupsTotalModel => r.body)
    );
  }

  /** Path part for operation `basicCallLogsGetBasicCallLogsData()` */
  static readonly BasicCallLogsGetBasicCallLogsDataPath = '/api/reports/basic-call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicCallLogsGetBasicCallLogsData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetBasicCallLogsData$Response(params: BasicCallLogsGetBasicCallLogsData$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicCallLogsGridResponse>> {
    return basicCallLogsGetBasicCallLogsData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicCallLogsGetBasicCallLogsData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetBasicCallLogsData(params: BasicCallLogsGetBasicCallLogsData$Params, context?: HttpContext): Observable<BasicCallLogsGridResponse> {
    return this.basicCallLogsGetBasicCallLogsData$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicCallLogsGridResponse>): BasicCallLogsGridResponse => r.body)
    );
  }

  /** Path part for operation `basicCallLogsGetExport()` */
  static readonly BasicCallLogsGetExportPath = '/api/reports/basic-call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicCallLogsGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetExport$Response(params: BasicCallLogsGetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return basicCallLogsGetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicCallLogsGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetExport(params: BasicCallLogsGetExport$Params, context?: HttpContext): Observable<string> {
    return this.basicCallLogsGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsGetCallLogsData()` */
  static readonly CallLogsGetCallLogsDataPath = '/api/reports/call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsGetCallLogsData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetCallLogsData$Response(params: CallLogsGetCallLogsData$Params, context?: HttpContext): Observable<StrictHttpResponse<CallLogsGridResponse>> {
    return callLogsGetCallLogsData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsGetCallLogsData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetCallLogsData(params: CallLogsGetCallLogsData$Params, context?: HttpContext): Observable<CallLogsGridResponse> {
    return this.callLogsGetCallLogsData$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogsGridResponse>): CallLogsGridResponse => r.body)
    );
  }

  /** Path part for operation `callLogsGetExport()` */
  static readonly CallLogsGetExportPath = '/api/reports/call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetExport$Response(params: CallLogsGetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return callLogsGetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetExport(params: CallLogsGetExport$Params, context?: HttpContext): Observable<string> {
    return this.callLogsGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsFixCdrRecords()` */
  static readonly CallLogsFixCdrRecordsPath = '/api/reports/fix-duplicate-records';

  /**
   * Fix transferred duplicate records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsFixCdrRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsFixCdrRecords$Response(params: CallLogsFixCdrRecords$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return callLogsFixCdrRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Fix transferred duplicate records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsFixCdrRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsFixCdrRecords(params: CallLogsFixCdrRecords$Params, context?: HttpContext): Observable<string> {
    return this.callLogsFixCdrRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsRecoverMergedRecords()` */
  static readonly CallLogsRecoverMergedRecordsPath = '/api/reports/recover-duplicate-records';

  /**
   * Restore deleted/merged records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsRecoverMergedRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsRecoverMergedRecords$Response(params: CallLogsRecoverMergedRecords$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return callLogsRecoverMergedRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore deleted/merged records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsRecoverMergedRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsRecoverMergedRecords(params: CallLogsRecoverMergedRecords$Params, context?: HttpContext): Observable<string> {
    return this.callLogsRecoverMergedRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `reportsGetCallDetails()` */
  static readonly ReportsGetCallDetailsPath = '/api/reports/call-record/{id}';

  /**
   * Get Call Record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsGetCallDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetCallDetails$Response(params: ReportsGetCallDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<RawCallModel>> {
    return reportsGetCallDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Call Record.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsGetCallDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetCallDetails(params: ReportsGetCallDetails$Params, context?: HttpContext): Observable<RawCallModel> {
    return this.reportsGetCallDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<RawCallModel>): RawCallModel => r.body)
    );
  }

  /** Path part for operation `reportsGetPbxAccountData()` */
  static readonly ReportsGetPbxAccountDataPath = '/api/reports/account-lookup/{domain}';

  /**
   * Get Pbx Extensions Lookup list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsGetPbxAccountData()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetPbxAccountData$Response(params: ReportsGetPbxAccountData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PbxAccountLookupModel>>> {
    return reportsGetPbxAccountData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Pbx Extensions Lookup list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsGetPbxAccountData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetPbxAccountData(params: ReportsGetPbxAccountData$Params, context?: HttpContext): Observable<Array<PbxAccountLookupModel>> {
    return this.reportsGetPbxAccountData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PbxAccountLookupModel>>): Array<PbxAccountLookupModel> => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetSummaryData()` */
  static readonly GroupsSummaryGetSummaryDataPath = '/api/reports/groups-summary-data';

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetSummaryData$Response(params: GroupsSummaryGetSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupsSummaryResponse>> {
    return groupsSummaryGetSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetSummaryData(params: GroupsSummaryGetSummaryData$Params, context?: HttpContext): Observable<GroupsSummaryResponse> {
    return this.groupsSummaryGetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsSummaryResponse>): GroupsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetAgentsSummaryData()` */
  static readonly GroupsSummaryGetAgentsSummaryDataPath = '/api/reports/groups-agent-summary-data';

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetAgentsSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetAgentsSummaryData$Response(params: GroupsSummaryGetAgentsSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupsAgentSummaryGridResponse>> {
    return groupsSummaryGetAgentsSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetAgentsSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetAgentsSummaryData(params: GroupsSummaryGetAgentsSummaryData$Params, context?: HttpContext): Observable<GroupsAgentSummaryGridResponse> {
    return this.groupsSummaryGetAgentsSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsAgentSummaryGridResponse>): GroupsAgentSummaryGridResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetExport()` */
  static readonly GroupsSummaryGetExportPath = '/api/reports/groups-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExport$Response(params: GroupsSummaryGetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return groupsSummaryGetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExport(params: GroupsSummaryGetExport$Params, context?: HttpContext): Observable<string> {
    return this.groupsSummaryGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetExportAgents()` */
  static readonly GroupsSummaryGetExportAgentsPath = '/api/reports/groups-summary/export-agents';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetExportAgents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExportAgents$Response(params: GroupsSummaryGetExportAgents$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return groupsSummaryGetExportAgents(this.http, this.rootUrl, params, context);
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetExportAgents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExportAgents(params: GroupsSummaryGetExportAgents$Params, context?: HttpContext): Observable<string> {
    return this.groupsSummaryGetExportAgents$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersSummaryGetUsersDashboardData()` */
  static readonly UsersSummaryGetUsersDashboardDataPath = '/api/reports/users-dashboard-data';

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryGetUsersDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersDashboardData$Response(params: UsersSummaryGetUsersDashboardData$Params, context?: HttpContext): Observable<StrictHttpResponse<UsersTotalModel>> {
    return usersSummaryGetUsersDashboardData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryGetUsersDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersDashboardData(params: UsersSummaryGetUsersDashboardData$Params, context?: HttpContext): Observable<UsersTotalModel> {
    return this.usersSummaryGetUsersDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UsersTotalModel>): UsersTotalModel => r.body)
    );
  }

  /** Path part for operation `usersSummaryGetUsersSummaryData()` */
  static readonly UsersSummaryGetUsersSummaryDataPath = '/api/reports/users-summary-data';

  /**
   * Get agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryGetUsersSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersSummaryData$Response(params: UsersSummaryGetUsersSummaryData$Params, context?: HttpContext): Observable<StrictHttpResponse<UsersSummaryGridResponse>> {
    return usersSummaryGetUsersSummaryData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryGetUsersSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersSummaryData(params: UsersSummaryGetUsersSummaryData$Params, context?: HttpContext): Observable<UsersSummaryGridResponse> {
    return this.usersSummaryGetUsersSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UsersSummaryGridResponse>): UsersSummaryGridResponse => r.body)
    );
  }

  /** Path part for operation `usersSummaryGetExport()` */
  static readonly UsersSummaryGetExportPath = '/api/reports/users-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetExport$Response(params: UsersSummaryGetExport$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return usersSummaryGetExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetExport(params: UsersSummaryGetExport$Params, context?: HttpContext): Observable<string> {
    return this.usersSummaryGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `huntGroupsDashboardGetGroupsDashboardData()` */
  static readonly HuntGroupsDashboardGetGroupsDashboardDataPath = '/api/reports/hunt-groups-dashboard-data';

  /**
   * Get hunt groups dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `huntGroupsDashboardGetGroupsDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  huntGroupsDashboardGetGroupsDashboardData$Response(params: HuntGroupsDashboardGetGroupsDashboardData$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupsTotalModel>> {
    return huntGroupsDashboardGetGroupsDashboardData(this.http, this.rootUrl, params, context);
  }

  /**
   * Get hunt groups dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `huntGroupsDashboardGetGroupsDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  huntGroupsDashboardGetGroupsDashboardData(params: HuntGroupsDashboardGetGroupsDashboardData$Params, context?: HttpContext): Observable<GroupsTotalModel> {
    return this.huntGroupsDashboardGetGroupsDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsTotalModel>): GroupsTotalModel => r.body)
    );
  }

}
