/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanResponse } from '../models/boolean-response';
import { CurrentUser } from '../models/current-user';
import { TwoFactorSetupModel } from '../models/two-factor-setup-model';
import { UserProfile } from '../models/user-profile';
import { usersAlive } from '../fn/users/users-alive';
import { UsersAlive$Params } from '../fn/users/users-alive';
import { usersProfile } from '../fn/users/users-profile';
import { UsersProfile$Params } from '../fn/users/users-profile';
import { usersSaveProfile } from '../fn/users/users-save-profile';
import { UsersSaveProfile$Params } from '../fn/users/users-save-profile';
import { usersSaveUiSettings } from '../fn/users/users-save-ui-settings';
import { UsersSaveUiSettings$Params } from '../fn/users/users-save-ui-settings';
import { usersTwoFactorSetUp } from '../fn/users/users-two-factor-set-up';
import { UsersTwoFactorSetUp$Params } from '../fn/users/users-two-factor-set-up';
import { usersTwoFactorVerify } from '../fn/users/users-two-factor-verify';
import { UsersTwoFactorVerify$Params } from '../fn/users/users-two-factor-verify';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersAlive()` */
  static readonly UsersAlivePath = '/api/users/alive';

  /**
   * Check if user session is valid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlive()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlive$Response(params?: UsersAlive$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentUser>> {
    return usersAlive(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if user session is valid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlive(params?: UsersAlive$Params, context?: HttpContext): Observable<CurrentUser> {
    return this.usersAlive$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `usersProfile()` */
  static readonly UsersProfilePath = '/api/users/profile';

  /**
   * Current user account details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersProfile$Response(params?: UsersProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProfile>> {
    return usersProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Current user account details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersProfile(params?: UsersProfile$Params, context?: HttpContext): Observable<UserProfile> {
    return this.usersProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfile>): UserProfile => r.body)
    );
  }

  /** Path part for operation `usersSaveProfile()` */
  static readonly UsersSaveProfilePath = '/api/users/profile';

  /**
   * Save current user account details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSaveProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveProfile$Response(params: UsersSaveProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return usersSaveProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Save current user account details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSaveProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveProfile(params: UsersSaveProfile$Params, context?: HttpContext): Observable<void> {
    return this.usersSaveProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersSaveUiSettings()` */
  static readonly UsersSaveUiSettingsPath = '/api/users/ui-settings';

  /**
   * Save current user UI Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSaveUiSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveUiSettings$Response(params: UsersSaveUiSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return usersSaveUiSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Save current user UI Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSaveUiSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveUiSettings(params: UsersSaveUiSettings$Params, context?: HttpContext): Observable<void> {
    return this.usersSaveUiSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersTwoFactorSetUp()` */
  static readonly UsersTwoFactorSetUpPath = '/api/users/two-factor-setup/{provider}';

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersTwoFactorSetUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorSetUp$Response(params: UsersTwoFactorSetUp$Params, context?: HttpContext): Observable<StrictHttpResponse<TwoFactorSetupModel>> {
    return usersTwoFactorSetUp(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersTwoFactorSetUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorSetUp(params: UsersTwoFactorSetUp$Params, context?: HttpContext): Observable<TwoFactorSetupModel> {
    return this.usersTwoFactorSetUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<TwoFactorSetupModel>): TwoFactorSetupModel => r.body)
    );
  }

  /** Path part for operation `usersTwoFactorVerify()` */
  static readonly UsersTwoFactorVerifyPath = '/api/users/two-factor-verify/{provider}';

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersTwoFactorVerify()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorVerify$Response(params: UsersTwoFactorVerify$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanResponse>> {
    return usersTwoFactorVerify(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersTwoFactorVerify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorVerify(params: UsersTwoFactorVerify$Params, context?: HttpContext): Observable<BooleanResponse> {
    return this.usersTwoFactorVerify$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

}
