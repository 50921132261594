/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { notificationGetExportFile } from '../fn/notifications/notification-get-export-file';
import { NotificationGetExportFile$Params } from '../fn/notifications/notification-get-export-file';
import { notificationGetExportNotifications } from '../fn/notifications/notification-get-export-notifications';
import { NotificationGetExportNotifications$Params } from '../fn/notifications/notification-get-export-notifications';
import { notificationGetLatestNotifications } from '../fn/notifications/notification-get-latest-notifications';
import { NotificationGetLatestNotifications$Params } from '../fn/notifications/notification-get-latest-notifications';
import { notificationMarkAllAsRead } from '../fn/notifications/notification-mark-all-as-read';
import { NotificationMarkAllAsRead$Params } from '../fn/notifications/notification-mark-all-as-read';
import { notificationMarkAsRead } from '../fn/notifications/notification-mark-as-read';
import { NotificationMarkAsRead$Params } from '../fn/notifications/notification-mark-as-read';
import { notificationMarkAsUnread } from '../fn/notifications/notification-mark-as-unread';
import { NotificationMarkAsUnread$Params } from '../fn/notifications/notification-mark-as-unread';
import { NotificationModel } from '../models/notification-model';
import { notificationSubscribe } from '../fn/notifications/notification-subscribe';
import { NotificationSubscribe$Params } from '../fn/notifications/notification-subscribe';
import { notificationUnsubscribe } from '../fn/notifications/notification-unsubscribe';
import { NotificationUnsubscribe$Params } from '../fn/notifications/notification-unsubscribe';

@Injectable({ providedIn: 'root' })
export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationGetLatestNotifications()` */
  static readonly NotificationGetLatestNotificationsPath = '/api/notifications/list';

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetLatestNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetLatestNotifications$Response(params?: NotificationGetLatestNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationModel>>> {
    return notificationGetLatestNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetLatestNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetLatestNotifications(params?: NotificationGetLatestNotifications$Params, context?: HttpContext): Observable<Array<NotificationModel>> {
    return this.notificationGetLatestNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationModel>>): Array<NotificationModel> => r.body)
    );
  }

  /** Path part for operation `notificationGetExportNotifications()` */
  static readonly NotificationGetExportNotificationsPath = '/api/notifications/export-list';

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetExportNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportNotifications$Response(params?: NotificationGetExportNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationModel>>> {
    return notificationGetExportNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetExportNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportNotifications(params?: NotificationGetExportNotifications$Params, context?: HttpContext): Observable<Array<NotificationModel>> {
    return this.notificationGetExportNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationModel>>): Array<NotificationModel> => r.body)
    );
  }

  /** Path part for operation `notificationGetExportFile()` */
  static readonly NotificationGetExportFilePath = '/api/notifications/export/{filename}';

  /**
   * Download generated file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetExportFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportFile$Response(params: NotificationGetExportFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return notificationGetExportFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Download generated file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetExportFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportFile(params: NotificationGetExportFile$Params, context?: HttpContext): Observable<Blob> {
    return this.notificationGetExportFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `notificationMarkAsUnread()` */
  static readonly NotificationMarkAsUnreadPath = '/api/notifications/mark-as-unread';

  /**
   * Mark notification as unread.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationMarkAsUnread()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsUnread$Response(params: NotificationMarkAsUnread$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationMarkAsUnread(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark notification as unread.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationMarkAsUnread$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsUnread(params: NotificationMarkAsUnread$Params, context?: HttpContext): Observable<void> {
    return this.notificationMarkAsUnread$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationMarkAsRead()` */
  static readonly NotificationMarkAsReadPath = '/api/notifications/mark-as-read';

  /**
   * Mark notification as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationMarkAsRead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsRead$Response(params: NotificationMarkAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationMarkAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark notification as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationMarkAsRead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsRead(params: NotificationMarkAsRead$Params, context?: HttpContext): Observable<void> {
    return this.notificationMarkAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationMarkAllAsRead()` */
  static readonly NotificationMarkAllAsReadPath = '/api/notifications/mark-all-as-read';

  /**
   * Mark all notification as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationMarkAllAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationMarkAllAsRead$Response(params?: NotificationMarkAllAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationMarkAllAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark all notification as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationMarkAllAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationMarkAllAsRead(params?: NotificationMarkAllAsRead$Params, context?: HttpContext): Observable<void> {
    return this.notificationMarkAllAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationSubscribe()` */
  static readonly NotificationSubscribePath = '/api/notifications/subscribe';

  /**
   * Subscribe for push notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationSubscribe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationSubscribe$Response(params: NotificationSubscribe$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationSubscribe(this.http, this.rootUrl, params, context);
  }

  /**
   * Subscribe for push notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationSubscribe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationSubscribe(params: NotificationSubscribe$Params, context?: HttpContext): Observable<void> {
    return this.notificationSubscribe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationUnsubscribe()` */
  static readonly NotificationUnsubscribePath = '/api/notifications/unsubscribe';

  /**
   * Unsubscribe for push notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationUnsubscribe()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationUnsubscribe$Response(params?: NotificationUnsubscribe$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationUnsubscribe(this.http, this.rootUrl, params, context);
  }

  /**
   * Unsubscribe for push notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationUnsubscribe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationUnsubscribe(params?: NotificationUnsubscribe$Params, context?: HttpContext): Observable<void> {
    return this.notificationUnsubscribe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
